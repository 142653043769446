import api from 'api';
import { PayrollGetUserByIdArgs } from '../types';
import { AppError } from 'utils/AppError';
import { GetFnfDismissalDateSchemaContract } from 'schemas/GetFnfDismissalDateSchema';

export const getDismissalDate = async (
  args: PayrollGetUserByIdArgs,
): Promise<GetFnfDismissalDateSchemaContract> => {
  try {
    const user = await api.userQueries.getUserbyId(args);
    return {
      deactivate_date: user.payrollGetUser.data.deactivateDate as string,
      resignation_date: user.payrollGetUser.data.deactivateDate as string,
    };
  } catch (e: Error | any) {
    throw new AppError({ messageForUser: e.message });
  }
};
