import api from 'api';
import { PayrollListSalaryComponentsQueryVariables } from 'api/gql-utils/salaryComponents/types';
import { DeductionsComponentType, EarningsComponentType } from 'components/SalaryComponents/types';
import { useQuery } from 'react-query';
import queryKeys from '../components/queries';
import { filterSalaryComponents } from '../utils';

type Props = {
  requestParams: PayrollListSalaryComponentsQueryVariables;
  enabled?: boolean;
};

const useSalaryComponentsList = <
  ComponentType extends EarningsComponentType | DeductionsComponentType,
>({
  requestParams,
  enabled,
}: Props) => {
  const { isLoading, data = [] } = useQuery({
    queryKey: [queryKeys.QUERY_LIST_SALARY_COMPONENTS, requestParams],
    queryFn: () => api.salaryComponents.queries.listSalaryComponents<ComponentType>(requestParams),
    select: (data) => {
      return data?.payrollListSalaryComponents?.data?.salaryComponents || [];
    },
    enabled,
  });

  const getSalaryComponentById = (id: string) => {
    return data.find((item) => item.id === id);
  };

  const searchSalaryComponents = (ignoreKeys: string[] = []) => {
    return filterSalaryComponents<ComponentType>(data, '', ignoreKeys);
  };

  return {
    isFetchingSalaryComponents: isLoading,
    searchSalaryComponents,
    getSalaryComponentById,
  };
};

export default useSalaryComponentsList;
