import { ComponentType } from 'react';
import {
  ArrowUpRightIcon,
  LayoutIcon,
  ReportsIcon,
  StampIcon,
  CalendarIcon,
  TagIcon,
  BookIcon,
  HelpCircleIcon,
  TaxPaymentsIcon,
  LifeBuoyIcon,
  UsersIcon,
  VendorPaymentsIcon,
  PayrollForStartupOrSmeIcon,
  StorefrontIcon,
  AppStoreIcon,
  SettingsIcon,
  IconProps,
  RupeeIcon,
} from '@razorpay/blade/components';
import { SidebarApiSchemaContract } from 'schemas/SidebarApiSchema';

export type NavSection = {
  key: string;
  type: 'section';
  title?: string;
  maxItemsVisible?: number;
  items: NavItem[];
};

export type NavItem = {
  icon?: ComponentType<IconProps>;
  isEnabled: boolean;
  title: string;
  href?: string;
  key: string;
  items?: NavItem[];
};

export const NavItemKeys = {
  DASHBOARD: 'DASHBOARD',
  MY_PAY_SLIPS: 'MY_PAY_SLIPS',
  ATTENDANCE: 'ATTENDANCE',
  TAX_DEDUCTIONS: 'TAX_DEDUCTIONS',
  REIMBURSEMENTS: 'REIMBURSEMENTS',
  DOCUMENTS: 'DOCUMENTS',
  HELP: 'HELP',
  PEOPLE: 'PEOPLE',
  PAYROLL: 'PAYROLL',
  INSURANCE: 'INSURANCE',
  RUN_PAYROLL: 'RUN_PAYROLL',
  ADHOC_PAYMENT: 'ADHOC_PAYMENT',
  ADVANCE_SALARY_PAYMENT: 'ADVANCE_SALARY_PAYMENT',
  PAY_REIMBURSEMENT: 'PAY_REIMBURSEMENT',
  LOANS: 'LOANS',
  PAY_CONTRACTORS: 'PAY_CONTRACTORS',
  CONTRACTOR_PAYMENTS: 'CONTRACTOR_PAYMENTS',
  CONTRACTOR_REIMBURSEMENTS: 'CONTRACTOR_REIMBURSEMENTS',
  APPROVALS: 'APPROVALS',
  REPORTS: 'REPORTS',
  COMPANY_DETAILS: 'COMPANY_DETAILS',
  SETTINGS: 'SETTINGS',
  INSURANCE_ADMIN: 'INSURANCE_ADMIN',
  INTEGRATIONS: 'INTEGRATIONS',
  REQUEST_PAYMENT: 'REQUEST_PAYMENT',
};

const iconMap: Record<string, ComponentType<IconProps>> = {
  DASHBOARD: LayoutIcon,
  MY_PAY_SLIPS: ArrowUpRightIcon,
  ATTENDANCE: CalendarIcon,
  TAX_DEDUCTIONS: TaxPaymentsIcon,
  REIMBURSEMENTS: TagIcon,
  DOCUMENTS: BookIcon,
  INSURANCE: LifeBuoyIcon,
  INSURANCE_ADMIN: LifeBuoyIcon,
  HELP: HelpCircleIcon,
  PEOPLE: UsersIcon,
  PAYROLL: PayrollForStartupOrSmeIcon,
  PAY_CONTRACTORS: VendorPaymentsIcon,
  APPROVALS: StampIcon,
  REPORTS: ReportsIcon,
  COMPANY_DETAILS: StorefrontIcon,
  SETTINGS: SettingsIcon,
  INTEGRATIONS: AppStoreIcon,
  REQUEST_PAYMENT: RupeeIcon,
};

const transformSidebarSchemaToNavItems = (
  sidebarSchema: SidebarApiSchemaContract,
  iconMap: Record<string, ComponentType<IconProps>>,
): Record<string, NavItem> => {
  const navItems: Record<string, NavItem> = {};

  // Helper function to transform a single schema item to NavItem
  const transformSchemaItemToNavItem = (item: SidebarApiSchemaContract[0]): NavItem => {
    return {
      icon: iconMap[item.key],
      title: item.name,
      href: item.url,
      key: item.key,
      isEnabled: item.isEnabled,
    };
  };

  // Process each item in the schema
  sidebarSchema.forEach((item) => {
    navItems[item.key] = transformSchemaItemToNavItem(item);

    if (item.childElements && item.childElements.length > 0) {
      const childItems = item.childElements
        .map(transformNavItem)
        .filter((item): item is NavItem => item !== null);

      if (childItems.length > 0) {
        navItems[item.key].items = childItems;
      }
    }
  });

  return navItems;
};

const EMPLOYEE_SECTION = [
  NavItemKeys.DASHBOARD,
  NavItemKeys.MY_PAY_SLIPS,
  NavItemKeys.ATTENDANCE,
  NavItemKeys.REIMBURSEMENTS,
  NavItemKeys.TAX_DEDUCTIONS,
  NavItemKeys.DOCUMENTS,
  NavItemKeys.INSURANCE,
  NavItemKeys.HELP,
  NavItemKeys.REQUEST_PAYMENT,
];

const ADMIN_SECTION = [
  NavItemKeys.PEOPLE,
  NavItemKeys.PAYROLL,
  NavItemKeys.PAY_CONTRACTORS,
  NavItemKeys.APPROVALS,
  NavItemKeys.REPORTS,
  // NavItemKeys.COMPANY_DETAILS,
  // NavItemKeys.SETTINGS,
  NavItemKeys.INSURANCE_ADMIN,
  NavItemKeys.INTEGRATIONS,
];

export function createNavSections(sidebar: SidebarApiSchemaContract): {
  sections: NavSection[];
  transformedItems: Record<string, NavItem>;
} {
  const transformedItems = transformSidebarSchemaToNavItems(sidebar, iconMap);

  if (Object.keys(transformedItems).length === 0) {
    return { sections: [], transformedItems };
  }

  const employeeItems: NavItem[] = EMPLOYEE_SECTION.map((key) => {
    return transformedItems[key];
  }).filter((item) => item && item.isEnabled);

  const adminItems: NavItem[] = ADMIN_SECTION.map((key) => {
    return transformedItems[key];
  }).filter((item) => item && item.isEnabled);

  const sections: NavSection[] = [];

  if (employeeItems.length > 0) {
    sections.push({
      type: 'section',
      key: 'employee',
      title: undefined,
      // extra check because the sidenav shows 0 more if the items happens to be equal
      maxItemsVisible: employeeItems.length > 5 && adminItems.length > 3 ? 5 : undefined,
      items: employeeItems,
    });
  }

  if (adminItems.length > 0) {
    sections.push({
      type: 'section',
      key: 'admin',
      title: 'Admin Options',
      maxItemsVisible: adminItems.length > 5 ? 5 : undefined,
      items: adminItems,
    });
  }

  return { sections, transformedItems };
}

function transformNavItem(sourceItem: SidebarApiSchemaContract[number]): NavItem | null {
  if (!sourceItem.isEnabled) {
    return null;
  }

  const item: NavItem = {
    title: sourceItem.name,
    key: sourceItem.key,
    isEnabled: sourceItem.isEnabled,
  };

  if (sourceItem.url) {
    item.href = sourceItem.url;
  }

  if (iconMap[sourceItem.key]) {
    item.icon = iconMap[sourceItem.key];
  }

  if (sourceItem.childElements && sourceItem.childElements.length > 0) {
    const childItems = sourceItem.childElements
      .map(transformNavItem)
      .filter((item): item is NavItem => item !== null);

    if (childItems.length > 0) {
      item.items = childItems;
    }
  }

  return item;
}
