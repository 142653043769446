import React, { useState } from 'react';
import { Box, HeartIcon, Menu, MenuOverlay, Text } from '@razorpay/blade/components';
import ChooseOrganizationMinimal from '../UserProfile/ChooseOrganizationMinimal';

export const CompanyBrandWithSelector = ({
  companyBrandName,
  orgId,
  hasMultipleOrgs,
}: {
  companyBrandName: string;
  orgId: number;
  hasMultipleOrgs: boolean;
}) => {
  const [isListOpen, setIsListOpen] = useState(false);

  const handleCloseList = () => {
    setIsListOpen(false);
  };

  const handleMenuOpenChange = ({ isOpen }: { isOpen: boolean }) => {
    setIsListOpen(isOpen);
  };

  if (!hasMultipleOrgs) {
    return (
      <Box display="flex" alignItems="center" marginX="spacing.4">
        <Text color="surface.text.gray.subtle" weight="semibold">
          We
        </Text>{' '}
        <HeartIcon color="interactive.icon.gray.subtle" size="small" marginX="spacing.2" />{' '}
        <Text color="surface.text.gray.subtle" weight="semibold">
          {companyBrandName}
        </Text>
      </Box>
    );
  }

  return (
    <Box>
      <Menu openInteraction="hover" isOpen={isListOpen} onOpenChange={handleMenuOpenChange}>
        <Box display="flex" alignItems="center" marginX="spacing.4">
          <Text color="surface.text.gray.subtle" weight="semibold">
            We
          </Text>{' '}
          <HeartIcon color="interactive.icon.gray.subtle" size="small" marginX="spacing.2" />{' '}
          <Text color="surface.text.gray.subtle" weight="semibold">
            {companyBrandName}
          </Text>
        </Box>
        <MenuOverlay>
          <ChooseOrganizationMinimal currentOrgId={orgId} onSuccess={handleCloseList} />
        </MenuOverlay>
      </Menu>
    </Box>
  );
};
