import { routePaths } from 'components/Routes/data';
import { useHistory } from 'react-router-dom';
import { salaryComponentParams } from '../constants';
import { EarningPayType } from '../types';

const createUrlParams = ({
  componentId,
  payType,
}: {
  componentId: string;
  payType?: EarningPayType;
}) => {
  const urlParams = new URLSearchParams({
    [salaryComponentParams.componentId]: componentId,
  });
  if (payType) {
    urlParams.set(salaryComponentParams.earningPayType, payType);
  }
  return urlParams;
};

export const useSalaryComponentHelpers = () => {
  const history = useHistory();

  const openEarningsCreation = () => {
    history.push(routePaths.salaryComponents.root);
  };

  const openDeductionsCreation = () => {
    history.push(routePaths.salaryComponents.deductionsListView);
  };
  const openEarningsEditPage = (componentId: string, payType?: EarningPayType) => {
    const urlParams = createUrlParams({ componentId, payType });
    history.push(`${routePaths.salaryComponents.edit.earnings.root}?${urlParams.toString()}`);
  };
  const openDeductionsEditPage = (componentId: string) => {
    const urlParams = createUrlParams({ componentId });
    history.push(`${routePaths.salaryComponents.edit.deductions.root}?${urlParams.toString()}`);
  };
  const openPredefinedEditPage = (componentId: string) => {
    const urlParams = createUrlParams({ componentId });
    history.push(`${routePaths.salaryComponents.edit.predefinedComponent}?${urlParams.toString()}`);
  };

  return {
    openEarningsCreation,
    openDeductionsCreation,
    openEarningsEditPage,
    openDeductionsEditPage,
    openPredefinedEditPage,
  };
};
