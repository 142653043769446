import { gql } from 'graphql-request';
import { graphQLApi } from 'utils/Api';
import {
  PayrollCreateOrUpdateSalaryComponentResponse,
  PayrollUpdateSalaryComponentVariables,
} from '../types';

export const updateSalaryComponentMutation = gql`
  mutation PayrollUpdateSalaryComponent(
    $id: String!
    $category: SalaryComponentCategory!
    $settings: PayrollSalaryComponentBehaviourInput!
    $description: String
    $payType: SalaryComponentEarningPayType
  ) {
    payrollUpdateSalaryComponent(
      id: $id
      category: $category
      settings: $settings
      description: $description
      payType: $payType
    ) {
      data {
        source
      }
      code
      success
      message
    }
  }
`;

const updateSalaryComponent = (queryVariables: PayrollUpdateSalaryComponentVariables) => {
  return graphQLApi<
    PayrollCreateOrUpdateSalaryComponentResponse,
    PayrollUpdateSalaryComponentVariables
  >({
    query: updateSalaryComponentMutation,
    queryVariables,
  });
};

export default updateSalaryComponent;
