const QUERY_LIST_ADDITIONS = 'QUERY_LIST_ADDITIONS';
const QUERY_LIST_DEDUCTIONS = 'QUERY_LIST_DEDUCTIONS';
const QUERY_LIST_SALARY_COMPONENTS = 'QUERY_LIST_SALARY_COMPONENTS';
const QUERY_LIST_ADDITIONS_DEDUCTIONS_LOP = 'QUERY_LIST_ADDITIONS_DEDUCTIONS_LOP';

const queryKeys = {
  QUERY_LIST_ADDITIONS,
  QUERY_LIST_DEDUCTIONS,
  QUERY_LIST_SALARY_COMPONENTS,
  QUERY_LIST_ADDITIONS_DEDUCTIONS_LOP
};

export default queryKeys;