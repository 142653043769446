export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function camelCaseToWords(str: string) {
  return str.replace(/([A-Z]+)/g, ' $1').replace(/([A-Z][a-z])/g, ' $1');
}

export function replaceHTMLSpecialChars(str: string): string {
  return str.replace(/&#039;|&#39;/g, "'")
      .replace(/&#34;/g, '"')
      .replace(/&amp;/g, '&');
}

export function truncateString(str: string, requiredLength: number) {
  return str.length > requiredLength ? str.substring(0, requiredLength - 3) + '...' : str;
}

export function capitalizeAllWordsInString(str: string) {
  return str
    .split(' ')
    .map((word) => capitalizeFirstLetter(word))
    .join(' ');
}
