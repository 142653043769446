import { gql } from 'graphql-request';
import { graphQLApi } from 'utils/Api';
import { ListSalaryComponentsResponse, PayrollListSalaryComponentsQueryVariables } from '../types';

export const listSalaryComponentsQuery = gql`
  query PayrollListSalaryComponents(
    $category: SalaryComponentCategory!
    $status: SalaryComponentStatus!
    $payType: SalaryComponentPayType
    $taxability: TaxabilitySettings
  ) {
    payrollListSalaryComponents(
      category: $category
      status: $status
      payType: $payType
      taxability: $taxability
    ) {
      data {
        source
        salaryComponents {
          id
          name
          description
          category
          settings {
            behaviour {
              pfBehaviour {
                includeInPfWages
              }
              ptBehaviour {
                includeInPtWages
              }
              esiBehaviour {
                includeInEsiWages
              }
              lwfBehaviour {
                includeInLwfWages
              }
              taxBehaviour {
                taxExemptionSection
                taxDeductionBehaviour
                taxExemptInNewRegime
                taxExemptInOldRegime
              }
              arrearBehaviour {
                considerInArrear
              }
              prorationBehaviour {
                supportProration
              }
              displayBehaviour {
                showZero
                visibleToEmployee
                displayName
              }
            }
            earningDetails {
              payType
            }
            deductionDetails {
              deductionType
              deductFrom
            }
            bonusDetails {
              clawbackDuration
              isTaxable
            }
            perquisiteDetails {
              isTaxable
            }
            nonPayableBenefitsDetails {
              isPartOfCTC
              visibilityToEmployee
            }
          }
          isPredefined
          isActive
          source
          defaultFormula
        }
      }
      code
      success
      message
    }
  }
`;

const listSalaryComponents = <T>(queryVariables: PayrollListSalaryComponentsQueryVariables) => {
  return graphQLApi<ListSalaryComponentsResponse<T>, PayrollListSalaryComponentsQueryVariables>({
    query: listSalaryComponentsQuery,
    queryVariables,
  });
};

export default listSalaryComponents;
