import React, { createContext, useContext, useMemo, useReducer } from 'react';
import { StatusIconProps } from '../components';
import { StatusState, WizardInfoContextValue, WizardInfoProviderProps } from './types';
import { wizardReducer } from './wizardReducer';

const WizardInfoContext = createContext<WizardInfoContextValue | null>(null);

export const WizardInfoProvider: React.FC<WizardInfoProviderProps> = ({
  children,
  currentStep,
  totalSteps,
}) => {
  const [status, dispatch] = useReducer(wizardReducer, {});

  const updateStepStatus = (stepIndex: number, status: StatusIconProps['status']) => {
    dispatch({ type: 'UPDATE_STATUS', stepIndex, status });
  };

  const resetStatus = () => {
    dispatch({ type: 'RESET_STATUS' });
  };

  const initializeStatus = (statuses: StatusState) => {
    dispatch({ type: 'INITIALIZE_STATUS', statuses });
  };

  const value = useMemo(
    () => ({
      currentStep,
      totalSteps,
      updateStepStatus,
      stepStatus: status,
      resetStatus,
      initializeStatus,
    }),
    [currentStep, totalSteps, status],
  );

  return <WizardInfoContext.Provider value={value}>{children}</WizardInfoContext.Provider>;
};

export const useWizardInfo = () => {
  const context = useContext(WizardInfoContext);
  if (context === null) {
    throw new Error('useWizardInfo must be used within a WizardInfoProvider');
  }
  return context as WizardInfoContextValue;
};
