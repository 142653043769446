import { gql } from 'graphql-request';
import { graphQLApi } from 'utils/Api';
import {
  PayrollGetEmployeeCompensationDataRequest,
  PayrollGetEmployeeCompensationDataResponse,
} from '../types';

export const getEmployeeCompensationDataQuery = gql`
  query PayrollGetCompensationData($userId: String!, $payrollMonth: String!) {
    payrollGetCompensationData(userId: $userId, payrollMonth: $payrollMonth) {
      data {
        payrolls {
          merchantId
          tenantId
          userId
          payrollMonth
          status
          isFnf
          netPayStatus
          isFinal
          isExecuted
          inputSnapshot {
            merchantMetadata {
              merchantId
              tenantId
            }
            userMetadata {
              userId
              type
              status
              isDeleted
              confidentialData {
                pan
                uan
                esiIp
                bankAccountNumber
                bankIfsc
              }
              stopSalary
              name
              department
              designation
              dateOfBirth
              location
              hireDate
              deactivateDate
              employeeId
              email
              nonOpfinPayrollConfig {
                financialYear
                currentEmployerSalary
                currentEmployerExemption
                currentEmployerTds
                previousEmployersSalary
                previousEmployersTds
              }
            }
            userRevision {
              userId
              revisionInfo {
                effectiveFromMonth
                effectiveToMonth
                fullCtc
                proratedCtc
              }
            }
          }
          data {
            earnings {
              name
              earningType
              description
              amount
              payType
              displayBehaviourV1 {
                showZero
                visibleToEmployee
                displayName
              }
              source
              taxBehaviourV1 {
                taxExemptionSection
                taxDeductionBehaviour
                taxExemptInNewRegime
                taxExemptInOldRegime
              }
            }
            compliances {
              pf {
                pfWages
                adminCharges
                employeeAmount
                employerAmount
                pastEmployeeContribution
              }
              esi {
                employeeAmount
                employerAmount
                esiWages
              }
              lwf {
                employeeAmount
                employerAmount
                lwfWages
              }
              pt {
                amount
                ptWages
              }
              tdsCalculation {
                tax
                cess
                rebate
                surcharge
                taxPayable
                tdsPerMonth
                forceTds
                totalPastTds
                pastTaxDeductedBySystem
              }
            }
            compensation {
              ctcComponent {
                earningComponents {
                  name
                  earningType
                  description
                  amount
                  payType
                  source
                }
                statutoryComponents {
                  amount
                  type
                }
              }
              nonCtcComponent {
                statutoryComponents {
                  amount
                  type
                }
              }
              monthlyCtc
            }
            standardDeduction
            payrollMonthsRemaining
            financialYear
            isPanMissing
            currentGrossSalary
            pastGrossSalary
            futureGrossSalary
            totalGrossSalary
            totalAnnualLta
            currentEmployerPfAndNpsContribution
            pastEmployerPfAndNpsContribution
            futureEmployerPfAndNpsContribution
            totalEmployerPfAndNpsContribution
            ctc
            netPay
            sumOfLopDeduction
            sumOfAllDeduction
            netTaxableIncome
            perquisites {
              pfAndNpsPerk
            }
            isTaxCalculatedOnVerifiedProof
            nonOpfinData {
              currentEmployerSalary
              currentEmployerExemption
              currentEmployerTds
              previousEmployerSalary
              previousEmployerTds
            }
            totalTaxDeduction
            taxDeductions {
              label
              amount
              limit
              declared
              verified
            }
            monthlyTaxes {
              month
              tax
            }
            section10Exemptions {
              name
              displayBehaviour {
                showZero
                visibleToEmployee
                displayName
              }
              description
              amount
              category
              componentSubCategory
              source
              section
            }
            hraExemptions {
              monthlyData {
                month
                actualHra
                exemptedHra
                rentAmount
                basicPlusDa
                allowedPercentageOfBasic
                excessRent
                taxableHra
                isMetro
              }
              pastExemption
              futureExemption
              currentExemption
              totalExemption
            }
            LtaExemption
            TotalPtExemption
            PastPtExemption
            CurrentPtExemption
            FuturePtExemption
            annualEarningsData {
              name
              earningType
              description
              amount
              payType
              source
            }
            annualEarningsExemptions {
              name
              earningType
              description
              amount
              payType
              source
            }
            annualEmployeePfContribution
          }
          sdis {
            inputSdiIds
            evaluatedSdis {
              earnings {
                name
                earningType
                sdiType
                description
                payType
                formulaRoot
                formulas
                taxBehaviour {
                  taxExemptInOldRegime
                  taxExemptInNewRegime
                  taxExemptionSection
                  taxDeductionBehaviour
                }
                arrearBehaviour {
                  considerInArrear
                  allowNegative
                }
                prorationBehaviour {
                  supportProration
                }
                pfBehaviour {
                  includeInPfWages
                }
                esiBehaviour {
                  includeInEsiWages
                }
                lwfBehaviour {
                  includeInLwfWages
                }
                ptBehaviour {
                  includeInPtWages
                }
              }
              pfV1 {
                name
                sdiType
                isApplicable
                formulaMap
                parametersForDisplay {
                  displayName
                  formula
                  includeIn
                }
                rawData
                formulas
                settings {
                  includeEmployerPfContributionInCtc
                  includeEmployerPfAdminChargesInCtc
                }
              }
              esiV1 {
                name
                sdiType
                isApplicable
                formulaMap
                rawData
                formulas
                settings {
                  isEsiCompulsory
                  includeEmployerEsiContributionInCtc
                }
              }
              lwfV1 {
                name
                sdiType
                isApplicable
                formulaMap
                rawData
                formulas
                settings {
                  includeEmployerLwfContributionInCtc
                }
              }
              tdsCalculationV1 {
                name
                sdiType
                isApplicable
                formulaMap
                rawData
                formulas
              }
              tdsDeclarationV1 {
                name
                sdiType
                isApplicable
                formulaMap
                rawData
                formulas
              }
              ptV1 {
                name
                sdiType
                isApplicable
                formulaMap
                rawData
                formulas
                settings {
                  ptLocation
                }
              }
              attendanceV1 {
                name
                sdiType
                rawData
                employeeWorkingDays
                totalWorkingDays
                daysOnLeave
                unpaidLeaves
                leavesAvailable
                hireDatePayrollMonth
                deactivatedDatePayrollMonth
              }
            }
          }
        }
        pagination {
          currentPage
          totalPages
          pageSize
          totalItems
        }
      }
      code
      success
      message
    }
  }
`;

const getEmployeeCompensationData = (request: PayrollGetEmployeeCompensationDataRequest) => {
  if (request.userId && request.payrollMonth) {
    return graphQLApi<
      PayrollGetEmployeeCompensationDataResponse,
      PayrollGetEmployeeCompensationDataRequest
    >({
      query: getEmployeeCompensationDataQuery,
      queryVariables: request,
    });
  } else {
    return Promise.reject('UserID and PayrollMonth are both required');
  }
};

export default getEmployeeCompensationData;
