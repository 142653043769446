import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface InitiateComplianceActionSchemaContract {
  data: {
    compliance_id?: string | number;
    message?: string;
    [k: string]: unknown;
  };
  [k: string]: unknown;
}

export default <JSONSchema4>{
  type: 'object',
  properties: {
    data: {
      type: 'object',
      properties: { compliance_id: { type: ['string', 'number'] }, message: { type: 'string' } },
    },
  },
  required: ['data'],
};
