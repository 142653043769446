import { GetComputedRlopArrearBreakdownContract } from './../schemas/GetComputedRlopArrearBreakdown';
import { OrderConfig } from 'components/RunPayroll/utils/types';
import GetArrearsSchema, { GetArrearsSchemaContract } from 'schemas/GetArrearsSchema';
import GetComputedComplianceArrearsSchema, {
  GetComputedComplianceArrearsSchemaContract,
} from 'schemas/GetComputedComplianceArrearsSchema';
import GetComputedRlopArrearBreakdown from 'schemas/GetComputedRlopArrearBreakdown';
import GetLOPAmountSchema, { GetLOPAmountSchemaContract } from 'schemas/GetLOPAmountSchema';
import GetLastFinalizedPayrollMonth, {
  GetLastFinalizedPayrollMonthContract,
} from 'schemas/GetLastFinalizedPayrollMonth';
import RXBalanceResponseSchema, {
  RXBalanceResponseSchemaContract,
} from 'schemas/RXBalanceResponseSchema';
import { RunPayrollBulkSkipSchemaContract } from 'schemas/RunPayrollBulkSkipSchema';
import { RunPayrollExecuteSchemaContract } from 'schemas/RunPayrollExecuteSchema';
import { RunPayrollExecuteWithOtpSchemaContract } from 'schemas/RunPayrollExecuteWithOtpSchema';
import { RunPayrollFinalizeSchemaContract } from 'schemas/RunPayrollFinalizeSchema';
import { RunPayrollRequestSchemaContract } from 'schemas/RunPayrollRequestSchema';
import RunPayrollSchema, { RunPayrollSchemaContract } from 'schemas/RunPayrollSchema';
import { RunPayrollSkipSchemaContract } from 'schemas/RunPayrollSkipSchema';
import { RunPayrollUpdateSchemaContract } from 'schemas/RunPayrollUpdateSchema';
import SuccessResponseSchema, {
  SuccessResponseSchemaContract,
} from 'schemas/SuccessResponseSchema';
import { getApi, postApi } from 'utils/Api';

type ResumeAllRequestContract = {
  payroll_month: string;
};

type RecalculatePayrollAmountContract = {
  payroll_month: string;
};

type AddRlopDaysContract = {
  payroll_month: string;
  source_details: {
    rlop_days: string | number;
    source_month: string;
  }[];
};

type GetComputedComplianceArrearsRequestContract = AddRlopDaysContract & {
  rlop_breakdown?: GetComputedRlopArrearBreakdownContract['rlop_arrears_breakdown'];
  salary_revision_breakdown?: GetComputedRlopArrearBreakdownContract['salary_revision_breakdown'];
  platform_arrears?: GetArrearsSchemaContract['arrears'];
};

export type EmailPfEcrReportRequestContract = {
  orgId: string;
  userId: string;
  payrollMonth: string;
};

export default {
  getRunPayrollData: (
    payrollMonth: string,
    searchPhrase: string,
    department: string[],
    locations: string[],
    page: number,
    limit: number,
    order: OrderConfig | null,
    statusFilter: string | null,
  ) => {
    const payload = {
      ...(payrollMonth && { payroll_month: payrollMonth }),
      ...(searchPhrase && { search_phrase: searchPhrase }),
      ...(statusFilter && { status_filter: statusFilter }),
      ...(department.length && { departments: department }),
      ...(locations.length && { locations: locations }),
      ...(order && Object.keys(order).length > 0 && { order: { ...order } }),
      page,
      limit,
    };

    return postApi<RunPayrollSchemaContract, RunPayrollRequestSchemaContract>({
      url: `/get-run-payroll`,
      responseSchema: RunPayrollSchema,
      requestData: payload,
    });
  },

  finalizePayroll: (payrollMonth: string) => {
    return postApi<
      SuccessResponseSchemaContract & { isPayrollBeingFinalized: boolean },
      RunPayrollFinalizeSchemaContract
    >({
      url: `/run-payroll/finalize`,
      responseSchema: SuccessResponseSchema,
      requestData: {
        payroll_month: payrollMonth,
      },
    });
  },

  unfinalizePayroll: (payrollMonth: string) => {
    return postApi<SuccessResponseSchemaContract, RunPayrollFinalizeSchemaContract>({
      url: `/run-payroll/unfinalize`,
      responseSchema: SuccessResponseSchema,
      requestData: {
        payroll_month: payrollMonth,
      },
    });
  },

  executePayroll: (
    payrollMonth: string,
    isDryRun: boolean,
    isConsentGrantedForSkippedPayrolls: boolean,
  ) => {
    return postApi<SuccessResponseSchemaContract, RunPayrollExecuteSchemaContract>({
      url: `/run-payroll/execute`,
      responseSchema: SuccessResponseSchema,
      requestData: {
        payroll_month: payrollMonth,
        is_dry_run: isDryRun,
        is_consent_granted_for_skipped_payrolls: isConsentGrantedForSkippedPayrolls,
      },
    });
  },
  executePayrollV2WithOtp: (
    payrollMonth: string,
    isDryRun: boolean,
    twoFactorAuthOtp: string,
    isConsentGrantedForSkippedPayrolls: boolean,
  ) => {
    return postApi<SuccessResponseSchemaContract, RunPayrollExecuteWithOtpSchemaContract>({
      url: `/run-payroll/execute-v2-with-otp`,
      responseSchema: SuccessResponseSchema,
      requestData: {
        payroll_month: payrollMonth,
        is_dry_run: isDryRun,
        two_factor_auth_otp: twoFactorAuthOtp,
        is_consent_granted_for_skipped_payrolls: isConsentGrantedForSkippedPayrolls,
      },
    });
  },

  executePayrollV2: (
    payrollMonth: string,
    isDryRun: boolean,
    isConsentGrantedForSkippedPayrolls: boolean,
  ) => {
    return postApi<SuccessResponseSchemaContract, RunPayrollExecuteSchemaContract>({
      url: `/run-payroll/execute-v2`,
      responseSchema: SuccessResponseSchema,
      requestData: {
        payroll_month: payrollMonth,
        is_dry_run: isDryRun,
        is_consent_granted_for_skipped_payrolls: isConsentGrantedForSkippedPayrolls,
      },
    });
  },

  bulkSkipPayroll: (payrollMonth: string, skipIds: number[]) => {
    return postApi<SuccessResponseSchemaContract, RunPayrollBulkSkipSchemaContract>({
      url: `/run-payroll/bulk-skip`,
      responseSchema: SuccessResponseSchema,
      requestData: {
        payroll_month: payrollMonth,
        ids: skipIds,
      },
    });
  },

  bulkResumePayroll: (payrollMonth: string, skipIds: number[]) => {
    return postApi<SuccessResponseSchemaContract, RunPayrollBulkSkipSchemaContract>({
      url: `/run-payroll/bulk-resume`,
      responseSchema: SuccessResponseSchema,
      requestData: {
        payroll_month: payrollMonth,
        ids: skipIds,
      },
    });
  },

  skipAllExceptThisPayroll: (payrollId: number, payrollMonth: string) => {
    return postApi<SuccessResponseSchemaContract, RunPayrollSkipSchemaContract>({
      url: `/run-payroll/skip-all-except-this`,
      responseSchema: SuccessResponseSchema,
      requestData: {
        payroll_id: payrollId,
        payroll_month: payrollMonth,
      },
    });
  },

  resumeAllPayroll: (payroll_month: string) => {
    return postApi<SuccessResponseSchemaContract, ResumeAllRequestContract>({
      url: `/run-payroll/resume-all`,
      responseSchema: SuccessResponseSchema,
      requestData: {
        payroll_month: payroll_month,
      },
    });
  },

  updatePayroll: (payload: RunPayrollUpdateSchemaContract) => {
    return postApi<SuccessResponseSchemaContract, RunPayrollUpdateSchemaContract>({
      url: `/run-payroll/update`,
      responseSchema: SuccessResponseSchema,
      requestData: payload,
    });
  },

  adminRecalculatePayrollAmount: (orgId: number) => (payrollMonth: string) => {
    return postApi<SuccessResponseSchemaContract, RecalculatePayrollAmountContract>({
      url: `/admin/${orgId}/recalculate-payroll-amount`,
      responseSchema: SuccessResponseSchema,
      requestData: {
        payroll_month: payrollMonth,
      },
    });
  },

  getRXBalance: (orgId: number) => {
    return getApi<RXBalanceResponseSchemaContract>({
      url: `/organization/${orgId}/rx-balance`,
      responseSchema: RXBalanceResponseSchema,
    });
  },

  getBalance: (orgId: number) => {
    return getApi<RXBalanceResponseSchemaContract>({
      url: `/organization/balance`,
      responseSchema: RXBalanceResponseSchema,
    });
  },

  getArrearsData: (userOrgId: number, payrollMonth: string) => {
    return getApi<GetArrearsSchemaContract>({
      url: `/payroll/${userOrgId}/arrears?payroll_month=${payrollMonth}`,
      responseSchema: GetArrearsSchema,
    });
  },

  getLOPAmount: (payrollId: number, lopDays: number) => {
    return getApi<GetLOPAmountSchemaContract>({
      url: `/run-payroll/lop-amount?payroll_id=${payrollId}&lop_days=${lopDays}`,
      responseSchema: GetLOPAmountSchema,
    });
  },

  getLastFinalizalizedPayrollMonth: (peopleId: string | number) => {
    return getApi<GetLastFinalizedPayrollMonthContract>({
      url: `/run-payroll/last-finalized-payroll-month?people_id=${peopleId}`,
      responseSchema: GetLastFinalizedPayrollMonth,
    });
  },

  addRlopDays: ({
    userOrg,
    requestData,
  }: {
    userOrg: string;
    requestData: AddRlopDaysContract;
  }) => {
    return postApi<SuccessResponseSchemaContract, AddRlopDaysContract>({
      url: `/rlop/user-org/${userOrg}/add-rlop-days`,
      responseSchema: SuccessResponseSchema,
      requestData,
    });
  },

  computeRlopArrearBreakdown: ({
    userOrg,
    requestData,
  }: {
    userOrg: string;
    requestData: AddRlopDaysContract;
  }) => {
    return postApi<GetComputedRlopArrearBreakdownContract, AddRlopDaysContract>({
      url: `/rlop/user-org/${userOrg}/add-rlop-days/validate`,
      responseSchema: GetComputedRlopArrearBreakdown,
      requestData,
    });
  },

  getComputedComplianceArrears: ({
    userOrg,
    requestData,
  }: {
    userOrg: string;
    requestData: GetComputedComplianceArrearsRequestContract;
  }) => {
    return postApi<
      GetComputedComplianceArrearsSchemaContract,
      GetComputedComplianceArrearsRequestContract
    >({
      url: `/payroll/${userOrg}/compute-compliance-arrears`,
      responseSchema: GetComputedComplianceArrearsSchema,
      requestData,
    });
  },
  emailPfEcrReport: (requestData: EmailPfEcrReportRequestContract) => {
    return postApi<SuccessResponseSchemaContract, EmailPfEcrReportRequestContract>({
      url: 'reports/pf-ecr',
      responseSchema: SuccessResponseSchema,
      requestData,
    });
  },
};
