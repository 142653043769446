import {
  AlertTriangleIcon,
  AutomatePayrollIcon,
  BellIcon,
  CheckCircleIcon,
  DownloadIcon,
  RupeeIcon,
  StampIcon,
  XCircleIcon,
} from '@razorpay/blade/components';

export function getBladeIconForNotificationType(type: string) {
  switch (type) {
    case 'InsuranceRefund':
      return RupeeIcon;

    case 'WorkflowPendingApprovalDashboard':
    case 'WorkflowMakerFinalizePayrollNotification':
    case 'WorkflowMakerEditPayrollNotification':
    case 'UpcomingPayrollReminder':
      return BellIcon;

    case 'PayrollProcessed':
    case 'PayrollProcessedToEmployee':
      return AutomatePayrollIcon;

    case 'BulkOnboardingRequestReceived':
    case 'BulkOnboardingRequestComplete':
    case 'HRMSSyncComplete':
    case 'ReimbursementApproved':
    case 'AdvanceSalaryApproved':
    case 'KycApproved':
      return CheckCircleIcon;

    case 'ReimbursementRejected':
    case 'AdvanceSalaryRejected':
      return XCircleIcon;

    case 'Form16':
    case 'TdsFilingAcknowledgement':
      return StampIcon;

    case 'BulkDownloadFailureNotification':
    case 'InvalidPanVerificationError':
      return AlertTriangleIcon;

    case 'BulkDownloadLinkNotification':
      return DownloadIcon;

    case 'CustomNotification':
    default:
      return BellIcon;
  }
}
