import {
  ActionList,
  ActionListItem,
  Box,
  ExternalLinkIcon,
  Link,
  SearchIcon,
  Text,
} from '@razorpay/blade/components';
import React from 'react';

const NoSearchResultsView = () => {
  return (
    <Box
      padding="spacing.5"
      display="grid"
      flexDirection="column"
      gap="spacing.5"
      placeItems="center">
      <SearchIcon size="medium" color="interactive.icon.gray.muted" />
      <Box display="grid" flexDirection="column" gap="spacing.3" placeItems="center">
        <Text weight="semibold">No results found</Text>
        <Text size="small" color="surface.text.gray.subtle" textAlign="center">
          Try searching again or create a new component
        </Text>
      </Box>
    </Box>
  );
};

type SearchResultsViewProps<T> = {
  searchItems: T[];
  onSelectItem: (searchItem: T) => void;
  itemToLabel: (item: T) => string;
  itemToKey: (item: T) => string;
  itemToDescription?: (item: T) => string;
};

const SearchResultsView = <T,>({
  searchItems,
  onSelectItem,
  itemToLabel,
  itemToKey,
  itemToDescription,
}: SearchResultsViewProps<T>) => {
  if (searchItems.length === 0) {
    return <NoSearchResultsView />;
  }

  return (
    <ActionList>
      {searchItems.map((item, index) => (
        <ActionListItem
          key={index}
          title={itemToLabel(item)}
          value={itemToKey(item).toString()}
          onClick={() => onSelectItem(item)}
          description={itemToDescription ? itemToDescription(item) : undefined}
        />
      ))}
    </ActionList>
  );
};

export default SearchResultsView;
