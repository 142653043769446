import { gql } from 'graphql-request';
import { graphQLApi } from 'utils/Api';
import {
  PayrollCreateOrUpdateSalaryComponentResponse,
  PayrollCreateOrUpdateSalaryComponentVariables,
} from '../types';

export const createSalaryComponentMutation = gql`
  mutation PayrollCreateSalaryComponent(
    $name: String!
    $category: SalaryComponentCategory!
    $settings: PayrollSalaryComponentSettingsInput!
    $isActive: Boolean
    $description: String
    $source: SalaryComponentSource
  ) {
    payrollCreateSalaryComponent(
      name: $name
      category: $category
      settings: $settings
      isActive: $isActive
      description: $description
      source: $source
    ) {
      code
      message
      success
      data {
        source
      }
    }
  }
`;

const createSalaryComponent = (queryVariables: PayrollCreateOrUpdateSalaryComponentVariables) => {
  return graphQLApi<
    PayrollCreateOrUpdateSalaryComponentResponse,
    PayrollCreateOrUpdateSalaryComponentVariables
  >({
    query: createSalaryComponentMutation,
    queryVariables,
  });
};

export default createSalaryComponent;
