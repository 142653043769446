import { gql } from 'graphql-request';
import { graphQLApi } from 'utils/Api';
import { GetEmployeeAdditionsDeductionsQueryVariables, ListDeductionsResponse } from '../types';

const listDeductionsQuery = gql`
  query PayrollListDeductions($userId: String!, $effectiveFrom: String!, $effectiveTo: String!) {
    payrollListDeductions(
      userId: $userId
      effectiveFrom: $effectiveFrom
      effectiveTo: $effectiveTo
    ) {
      code
      data {
        data {
          id
          componentName
          componentId
          userId
          type
          amount
          effectiveFrom
          effectiveTo
        }
        source
      }
      message
      success
    }
  }
`;

const listDeductions = (queryVariables: GetEmployeeAdditionsDeductionsQueryVariables) => {
  return graphQLApi<ListDeductionsResponse, GetEmployeeAdditionsDeductionsQueryVariables>({
    query: listDeductionsQuery,
    queryVariables,
  });
};

export default listDeductions;
