import { salaryComponentParams } from 'components/SalaryComponents/constants';
import { useSearchParams } from 'hooks/useSearchParams';
import { EarningPayType } from '../types';

export const useComponentParams = () => {
  const { urlParams } = useSearchParams();
  const componentId = urlParams.get(salaryComponentParams.componentId);
  const payType = urlParams.get(salaryComponentParams.earningPayType) as EarningPayType;
  const searchString = urlParams.toString();

  return { componentId, searchString, payType };
};
