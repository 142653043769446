import { JSONSchema4 } from 'json-schema';

// New interfaces for approve and reject leave requests
export interface ApproveRejectLeaveRequest {
  ids: number[];
  'user-id': number;
}

// New interface for approve and reject leave response
export interface ApproveRejectLeaveResponse {
  message: string;
}

interface DateObject {
  date: string;
  timezone_type: number;
  timezone: string;
}

// New schema for approve and reject leave response
export const ApproveRejectLeaveResponseSchema: JSONSchema4 = {
  type: 'object',
  properties: {
    message: { type: 'string' },
  },
  required: ['message'],
};

// Interface for apply leave request
export interface ApplyLeaveRequest {
  'from-date': string;
  'to-date': string;
  status: number;
  'leave-type': number | null;
  remarks: string;
  'user-id'?: number;
  'consider-leave-on-holiday'?: boolean;
}

// Interface for apply leave response
export interface ApplyLeaveResponse {
  dates: DateObject[];
  leaveDescription: string;
  hasAttendanceSet: boolean;
  hasHolidaySet: boolean;
  isRequest: boolean;
  isProxyRequest: boolean;
}

// Schema for apply leave response
export const ApplyLeaveResponseSchema: JSONSchema4 = {
  type: 'object',
  properties: {
    dates: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          date: { type: 'string' },
          timezone_type: { type: 'number' },
          timezone: { type: 'string' },
        },
        required: ['date', 'timezone_type', 'timezone'],
      },
    },
    leaveDescription: { type: 'string' },
    hasAttendanceSet: { type: 'boolean' },
    hasHolidaySet: { type: 'boolean' },
    isRequest: { type: 'boolean' },
    isProxyRequest: { type: 'boolean' },
  },
  required: [
    'dates',
    'leaveDescription',
    'hasAttendanceSet',
    'hasHolidaySet',
    'isRequest',
    'isProxyRequest',
  ],
};

export interface AttendanceRecord {
  id: number | null;
  peopleId: number | null;
  organizationId: number | null;
  date: DateObject;
  status: number | null;
  requestedStatus: number | null;
  leaveType: number | null;
  requestedLeaveType: number | null;
  checkIn: string | null;
  checkOut: string | null;
  duration: string | null;
  requestedCheckIn: string | null;
  requestedCheckOut: string | null;
  remarks: string | null;
  locationCheckin: string | null;
  locationCheckOut: string | null;
  statusDescription: string | null;
  requestedStatusDescription: string | null;
}

export type AttendanceResponse = AttendanceRecord[];

export const AttendanceResponseSchema: JSONSchema4 = {
  type: 'array',
  items: {
    type: 'object',
    properties: {
      id: { type: ['number', 'null'] },
      peopleId: { type: ['number', 'null'] },
      organizationId: { type: ['number', 'null'] },
      date: {
        type: 'object',
        properties: {
          date: { type: 'string' },
          timezone_type: { type: 'number' },
          timezone: { type: 'string' },
        },
        required: ['date', 'timezone_type', 'timezone'],
      },
      status: { type: ['number', 'null'] },
      requestedStatus: { type: ['number', 'null'] },
      leaveType: { type: ['number', 'null'] },
      requestedLeaveType: { type: ['number', 'null'] },
      checkIn: { type: ['string', 'null'] },
      checkOut: { type: ['string', 'null'] },
      duration: { type: ['string', 'null'] },
      requestedCheckIn: { type: ['string', 'null'] },
      requestedCheckOut: { type: ['string', 'null'] },
      remarks: { type: ['string', 'null'] },
      locationCheckin: { type: ['string', 'null'] },
      locationCheckOut: { type: ['string', 'null'] },
      statusDescription: { type: ['string', 'null'] },
      requestedStatusDescription: { type: ['string', 'null'] },
    },
    required: [
      'id',
      'peopleId',
      'organizationId',
      'date',
      'status',
      'requestedStatus',
      'leaveType',
      'requestedLeaveType',
      'checkIn',
      'checkOut',
      'duration',
      'requestedCheckIn',
      'requestedCheckOut',
      'remarks',
      'locationCheckin',
      'locationCheckOut',
      'statusDescription',
      'requestedStatusDescription',
    ],
  },
};

export interface AttendanceCheckInResponse {
  'attendance-id': number;
  'checkin-time': string;
}

export interface AttendanceCheckOutResponse {
  'attendance-id': number;
  'checkout-time': string;
}

// New schema for the check-in and check-out response
export const AttendanceCheckInResponseSchema: JSONSchema4 = {
  type: 'object',
  properties: {
    'attendance-id': { type: 'number' },
    'checkin-time': { type: 'string' },
  },
  required: ['attendance-id', 'checkin-time'],
};

export const AttendanceCheckOutResponseSchema: JSONSchema4 = {
  type: 'object',
  properties: {
    'attendance-id': { type: 'number' },
    'checkout-time': { type: 'string' },
  },
  required: ['attendance-id', 'checkout-time'],
};

// New type for check-in and check-out request
export type AttendanceActionRequest = {
  location?: string;
};

// New interface for delete attendance response
export interface DeleteAttendanceResponse {
  message: string;
}

export const DeleteAttendanceResponseSchema: JSONSchema4 = {
  type: 'object',
  properties: {
    message: { type: 'string' },
  },
  required: ['message'],
};

// New interface for update attendance request
export interface UpdateAttendanceRequest {
  date: string;
  status: number;
  leave_type?: number;
  checkin?: string;
  checkout?: string;
  remarks?: string;
  user_id?: number;
}

// New interface for update attendance response
export interface UpdateAttendanceResponse {
  message: string;
}

export const UpdateAttendanceResponseSchema: JSONSchema4 = {
  type: 'object',
  properties: {
    message: { type: 'string' },
  },
  required: ['message'],
};

interface LeaveEntry {
  date: DateObject;
  status: number;
}

interface LeavesTypeEntry {
  leave_description: string;
  leaves: LeaveEntry[];
}

export interface TodaysAttendanceResponse {
  people_id: number;
  show_web_checkin: boolean;
  is_checkin_done: boolean;
  is_attendance_marked_today: boolean;
  employee_name: string;
  has_biometric: boolean;
  checkin_time: string | null;
  checkout_time: string | null;
  leave_balance: {
    [key: string]: {
      leave_total: number;
      leave_description: string;
      leave_balance: number;
    };
  };
  leaves_taken: {
    [key: string]: LeavesTypeEntry;
  };
  can_approve_requests?: boolean;
}

export const TodaysAttendanceResponseSchema: JSONSchema4 = {
  type: 'object',
  properties: {
    people_id: { type: 'number' },
    show_web_checkin: { type: 'boolean' },
    is_checkin_done: { type: 'boolean' },
    is_attendance_marked_today: { type: 'boolean' },
    employee_name: { type: 'string' },
    has_biometric: { type: 'boolean' },
    checkin_time: { type: ['string', 'null'] },
    checkout_time: { type: ['string', 'null'] },
    leave_balance: {
      type: 'object',
      additionalProperties: {
        type: 'object',
        properties: {
          leave_total: { type: 'number' },
          leave_description: { type: 'string' },
          leave_balance: { type: 'number' },
        },
        required: ['leave_total', 'leave_description', 'leave_balance'],
      },
    },
    leaves_taken: {
      type: 'object',
      additionalProperties: {
        type: 'object',
        properties: {
          leave_description: { type: 'string' },
          leaves: {
            type: 'array',
            items: {
              type: 'object',
              properties: {
                date: {
                  type: 'object',
                  properties: {
                    date: { type: 'string' },
                    timezone_type: { type: 'number' },
                    timezone: { type: 'string' },
                  },
                  required: ['date', 'timezone_type', 'timezone'],
                },
                status: { type: 'number' },
              },
              required: ['date', 'status'],
            },
          },
        },
        required: ['leave_description', 'leaves'],
      },
    },
    can_approve_requests: { type: 'boolean' },
  },
  required: [
    'people_id',
    'show_web_checkin',
    'is_checkin_done',
    'is_attendance_marked_today',
    'employee_name',
    'has_biometric',
    'checkin_time',
    'checkout_time',
    'leave_balance',
    'leaves_taken',
  ],
};
