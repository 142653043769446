import {
  ApplyLeaveRequest,
  ApplyLeaveResponse,
  ApplyLeaveResponseSchema,
  ApproveRejectLeaveRequest,
  ApproveRejectLeaveResponse,
  ApproveRejectLeaveResponseSchema,
  AttendanceActionRequest,
  AttendanceCheckInResponse,
  AttendanceCheckInResponseSchema,
  AttendanceCheckOutResponse,
  AttendanceCheckOutResponseSchema,
  AttendanceResponse,
  AttendanceResponseSchema,
  DeleteAttendanceResponse,
  DeleteAttendanceResponseSchema,
  UpdateAttendanceRequest,
  UpdateAttendanceResponse,
  UpdateAttendanceResponseSchema,
  TodaysAttendanceResponse,
  TodaysAttendanceResponseSchema,
} from 'schemas/Attendance';
import { postApi, getApi } from 'utils/Api';
import { prepareUrlV2 } from 'utils/Urls';

const attendance = {
  fetchAttendance: (attendanceMonth: string, userId?: string | null) => {
    return getApi<AttendanceResponse>({
      url: prepareUrlV2('/attendance', { attendance_month: attendanceMonth, user_id: userId }),
      responseSchema: AttendanceResponseSchema,
    });
  },
  checkIn: ({ location = undefined }: { location?: string }) => {
    const payload: AttendanceActionRequest = {};
    if (location) {
      payload['location'] = location;
    }
    return postApi<AttendanceCheckInResponse, AttendanceActionRequest>({
      url: '/attendance/check-in',
      responseSchema: AttendanceCheckInResponseSchema,
      requestData: payload,
    });
  },
  checkOut: ({ location = undefined }: { location?: string }) => {
    const payload: AttendanceActionRequest = {};
    if (location) {
      payload['location'] = location;
    }
    return postApi<AttendanceCheckOutResponse, AttendanceActionRequest>({
      url: '/attendance/check-out',
      responseSchema: AttendanceCheckOutResponseSchema,
      requestData: payload,
    });
  },
  approveLeave: (data: ApproveRejectLeaveRequest) => {
    return postApi<ApproveRejectLeaveResponse, ApproveRejectLeaveRequest>({
      url: '/attendance/approve-leave',
      responseSchema: ApproveRejectLeaveResponseSchema,
      requestData: data,
    });
  },
  rejectLeave: (data: ApproveRejectLeaveRequest) => {
    return postApi<ApproveRejectLeaveResponse, ApproveRejectLeaveRequest>({
      url: '/attendance/reject-leave',
      responseSchema: ApproveRejectLeaveResponseSchema,
      requestData: data,
    });
  },
  deleteRequests: (data: { ids: number[] }) => {
    return postApi<ApproveRejectLeaveResponse, { ids: number[] }>({
      url: '/attendance/delete-requests',
      responseSchema: ApproveRejectLeaveResponseSchema,
      requestData: data,
    });
  },
  applyLeave: (data: ApplyLeaveRequest) => {
    return postApi<ApplyLeaveResponse, ApplyLeaveRequest>({
      url: '/attendance/apply-leave',
      responseSchema: ApplyLeaveResponseSchema,
      requestData: data,
    });
  },
  deleteAttendance: (attendanceId: number) => {
    return postApi<DeleteAttendanceResponse, void>({
      url: `/attendance/${attendanceId}/delete`,
      responseSchema: DeleteAttendanceResponseSchema,
    });
  },
  updateAttendance: (data: UpdateAttendanceRequest) => {
    return postApi<UpdateAttendanceResponse, UpdateAttendanceRequest>({
      url: '/attendance/update',
      responseSchema: UpdateAttendanceResponseSchema,
      requestData: data,
    });
  },
  fetchTodaysAttendance: (userId?: string | null) => {
    return getApi<TodaysAttendanceResponse>({
      url: prepareUrlV2('/attendance/today', { user_id: userId }),
      responseSchema: TodaysAttendanceResponseSchema,
    });
  },
};

export default attendance;
