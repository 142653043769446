import styled from 'styled-components';

interface ProfileCardProps {
  href?: string;
}

export const ProfileCard = styled.a<ProfileCardProps>(
  ({ theme }) => `
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: ${theme.spacing[2]}px;
  justify-content: space-between;
  margin-bottom: ${theme.spacing[2]}px;
  gap: ${theme.spacing[4]}px;
  border-radius: ${theme.border.radius.medium}px;

  &:hover {
    background-color: ${theme.colors.interactive.background.gray.fadedHighlighted};
  }
`,
);
