import { Box, Spinner } from '@razorpay/blade/components';
import React from 'react';

const WizardLoader = () => {
  return (
    <Box display="grid" placeItems="center" height="100vh">
      <Spinner accessibilityLabel="Loading" size="xlarge" />
    </Box>
  );
};

export { WizardLoader };
