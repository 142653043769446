import { SalaryComponent } from 'components/SalaryComponents/types';
import { getApi } from 'utils/Api';

export type SearchItem = {
  key: string | number;
  label: string;
  description?: string;
};

const getCompletions = ({ dataUrl, search }: { dataUrl: string; search: string }) => {
  // TODO: Change the implementation here once api's are available
  return getApi<SearchItem[]>({
    url: `${dataUrl}?search=${search}`,
    responseSchema: {},
  });
};

const getAdhocComponents = ({ dataUrl, search }: { dataUrl: string; search: string }) => {
  // TODO: Change the implementation here once api's are available
  return getApi<SalaryComponent[]>({
    url: `${dataUrl}?search=${search}`,
    responseSchema: {},
  });
};

const apis = { getCompletions, getAdhocComponents };

export default apis;
