import { gql } from 'graphql-request';
import { graphQLApi } from 'utils/Api';
import { BulkSkipPayrollResponse } from '../types';

const skipAllExceptThisMutation = gql`
  mutation PayrollSkipAllExceptThis($payroll_month: String!, $payroll_id: PositiveInt!) {
    payrollSkipAllExceptThis(payroll_month: $payroll_month, payroll_id: $payroll_id) {
      code
      data {
        success
      }
      message
      success
    }
  }
`;

const skipAllExceptThis = (queryVariables: { payroll_month: String; payroll_id: number }) => {
  return graphQLApi<BulkSkipPayrollResponse, { payroll_month: String; payroll_id: number }>({
    query: skipAllExceptThisMutation,
    queryVariables,
  });
};

export default skipAllExceptThis;
