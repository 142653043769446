import React from 'react';
import useReduxSelector from '../../../../../utils/useReduxSelector';
import {
  ActionList,
  ActionListItem,
  ActionListItemBadge,
  ActionListItemBadgeGroup,
  ActionListItemIcon,
  ActionListSection,
  Box,
  Spinner,
} from '@razorpay/blade/components';
import { getBladeIconForNotificationType } from './constants';
import { makeSpace } from '@razorpay/blade/utils';

export function NotificationsList() {
  const notifications = useReduxSelector((state) => state.notification);

  if (notifications.isFetching) {
    return (
      <Box height={makeSpace(300)} display="flex" justifyContent="center" alignItems="center">
        <Spinner size="medium" accessibilityLabel="fetching notifications" />
      </Box>
    );
  }

  if (Object.keys(notifications.data).length === 0) {
    return (
      <Box height={makeSpace(300)} display="flex" justifyContent="center" alignItems="center">
        No notifications
      </Box>
    );
  }

  return (
    <ActionList>
      {notifications.data &&
        Object.keys(notifications.data).map((month) => (
          <ActionListSection title={month.toLocaleUpperCase()} key={month.toLocaleUpperCase()}>
            {notifications.data[month].map((notification) => (
              <ActionListItem
                key={notification.id}
                leading={
                  <ActionListItemIcon icon={getBladeIconForNotificationType(notification.type)} />
                }
                title={notification.title}
                description={notification.desc}
                titleSuffix={
                  <ActionListItemBadgeGroup>
                    <ActionListItemBadge size="small" color="neutral">
                      {notification.date}
                    </ActionListItemBadge>
                    {!notification.readStatus ? (
                      <ActionListItemBadge size="small" color="positive">
                        New
                      </ActionListItemBadge>
                    ) : (
                      <></>
                    )}
                  </ActionListItemBadgeGroup>
                }
                value={notification.id}
                href={notification.url ? notification.url : '#'}
              />
            ))}
          </ActionListSection>
        ))}
    </ActionList>
  );
}
