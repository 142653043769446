import { Box, Heading } from '@razorpay/blade/components';
import React from 'react';

export const StepHeader = ({
  title,
  subtitle,
}: React.PropsWithChildren<{ title: string; subtitle: string }>) => (
  <Box gap="spacing.2" display="flex" marginBottom="spacing.9">
    <Heading size="medium" color="surface.text.gray.subtle">
      {title}
    </Heading>
    <Heading weight="regular" size="medium" color="surface.text.gray.muted">
      {subtitle}
    </Heading>
  </Box>
);
