import { useHistory, useLocation } from 'react-router-dom';

export type SearchParam<T extends string> = {
  key: T;
  value: string | string[];
};

const useSearchParams = <T extends string>() => {
  const location = useLocation();
  const pathname = location.pathname;
  const history = useHistory();
  const search = location.search;
  const urlParams = new URLSearchParams(search);

  const updateSearchParam = () => {
    const searchParams = urlParams.toString();

    history.replace({
      pathname,
      search: `?${searchParams}`,
    });
  };

  const addSearchParams = (params: SearchParam<T>[]) => {
    if (params.length === 0) {
      return;
    }
    params.forEach(({ key, value }) => {
      if (Array.isArray(value)) {
        urlParams.delete(key);
        value.forEach((v) => {
          urlParams.append(key, v);
        });
      } else {
        urlParams.set(key, value);
      }
    });
    updateSearchParam();
  };

  const removeSearchParams = (params: T[]) => {
    if (params.length === 0) {
      return;
    }
    params.forEach((param) => {
      urlParams.delete(param);
    });
    updateSearchParam();
  };

  return {
    urlParams,
    addSearchParams,
    removeSearchParams,
  };
};

export { useSearchParams };
