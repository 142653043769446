type ArrayFormat = 'comma' | 'repeat' | 'bracket' | 'json';

interface URLParamsOptions {
  arrayFormat?: ArrayFormat;
  baseUrl?: string;
}

export function getStaticMediaUrl(pathOrInlineData: string) {
  if (pathOrInlineData.includes('/static') && !pathOrInlineData.includes('http')) {
    if (process.env.REACT_APP_LOCAL_DEVELOPMENT_SERVER_EXTERNAL_LINK) {
      return `${
        process.env.REACT_APP_LOCAL_DEVELOPMENT_SERVER_EXTERNAL_LINK
      }/${pathOrInlineData.replace(/^\//, '')}`;
    } else {
      return `/${pathOrInlineData.replace(/^\//, '')}`;
    }
  }

  return pathOrInlineData;
}

/**
 *  @deprecated use prepareUrlV2 instead
 */
export function prepareUrl(
  url: string,
  params: Record<string, string | number | string[] | number[] | null | undefined>,
) {
  let paramsStringList = [];
  for (const key in params) {
    let value = params[key];
    if (value === null || value === undefined) {
      continue;
    }
    if (Array.isArray(value)) {
      value = value.join(',');
    }
    paramsStringList.push(`${key}=${value}`);
  }
  let paramsString = paramsStringList.length ? '?' + paramsStringList.join('&') : '';
  return `${url}${paramsString}`;
}

const isAbsoluteUrl = (url: string): boolean => {
  try {
    new URL(url);
    return true;
  } catch {
    return false;
  }
};

const formatArrayParam = (key: string, array: any[], format: ArrayFormat): [string, string][] => {
  switch (format) {
    case 'comma':
      // Don't encode individual items, just join with commas
      const commaValue = array.map(String).join(',');
      return [[key, commaValue]];
    case 'repeat':
      return array.map((value) => [key, String(value)]);
    case 'bracket':
      return array.map((value) => [key + '[]', String(value)]);
    case 'json':
      return [[key, JSON.stringify(array)]];
    default:
      return [[key, array.map(String).join(',')]];
  }
};

export const prepareUrlV2 = (
  path: string,
  params: Record<string, any>,
  options: URLParamsOptions = { arrayFormat: 'comma' },
): string => {
  let urlObject: URL;

  try {
    if (isAbsoluteUrl(path)) {
      urlObject = new URL(path);
    } else {
      // For relative paths, we need a base URL
      const baseUrl =
        options.baseUrl ||
        (typeof window !== 'undefined' ? window.location.origin : 'http://localhost');
      urlObject = new URL(path, baseUrl);
    }

    // Build search params manually to avoid automatic encoding
    const searchParams = new URLSearchParams();

    Object.entries(params).forEach(([key, value]) => {
      if (value === null || value === undefined) {
        return;
      }

      if (Array.isArray(value)) {
        const arrayParams = formatArrayParam(key, value, options.arrayFormat || 'comma');
        arrayParams.forEach(([paramKey, paramValue]) => {
          // Let URLSearchParams handle the key encoding
          searchParams.append(paramKey, paramValue);
        });
      } else {
        searchParams.append(key, value);
      }
    });

    urlObject.search = '?' + searchParams.toString();

    let result = urlObject.toString();

    // Remove trailing slash before query parameters if path is empty
    if (urlObject.pathname === '/') {
      result = result.replace('/?', '?');
    }

    // If it was a relative path and no baseUrl was provided, return only pathname and search
    if (!isAbsoluteUrl(path) && !options.baseUrl) {
      const pathname = urlObject.pathname === '/' ? '' : urlObject.pathname;
      return `${pathname}${urlObject.search}`;
    }

    return result;
  } catch (error) {
    throw new Error(`Invalid URL or path: ${path}`);
  }
};

export const getUrlWithoutQuery = (url: string) => {
  let cleanedUrl = '';
  try {
    const urlObject = new URL(url);
    urlObject.search = '';
    cleanedUrl = urlObject.href;
  } catch (e) {}

  return cleanedUrl;
};

export const getRedactedPath = (path: string) => {
  const regex = /\/\d+/g;
  return path?.replace(regex, '/ID');
};
