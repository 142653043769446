import { gql } from 'graphql-request';
import { graphQLApi } from 'utils/Api';
import { PayrollSetSalaryComponentStatusRequest, SetSalaryComponentStatusResponse } from '../types';

export const setSalaryComponentStatusMutation = gql`
  mutation PayrollSetSalaryComponentStatus(
    $id: String!
    $category: SalaryComponentCategory!
    $status: SalaryComponentSetStatus!
    $earningsPayType: SalaryComponentEarningPayType
  ) {
    payrollSetSalaryComponentStatus(
      id: $id
      category: $category
      status: $status
      earningsPayType: $earningsPayType
    ) {
      data {
        isActive
        source
      }
      code
      success
      message
    }
  }
`;

const setSalaryComponentStatus = (queryVariables: PayrollSetSalaryComponentStatusRequest) => {
  return graphQLApi<SetSalaryComponentStatusResponse, PayrollSetSalaryComponentStatusRequest>({
    query: setSalaryComponentStatusMutation,
    queryVariables,
  });
};

export default setSalaryComponentStatus;
