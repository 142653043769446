import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface GetComplianceActionsSchemaContract {
  payroll_month: string;
  actions: {
    'run-payroll': {
      status:
        | 'completely_executed'
        | 'partially_executed'
        | 'finalized'
        | 'not_initiated'
        | 'pending'
        | 'processing';
      meta: {
        last_payroll_executed?: null | string;
        payroll_executed_employees?: number;
        payroll_executed_employees_pending?: number;
        payroll_executed_employees_total?: number;
        display_name: string;
        message?: string;
        [k: string]: unknown;
      };
      [k: string]: unknown;
    };
    'pay-pt': {
      /**
       * This interface was referenced by `undefined`'s JSON-Schema definition
       * via the `patternProperty` "^[a-zA-Z ]+$".
       */
      [k: string]: {
        status:
          | 'in_progress'
          | 'not_initiated'
          | 'failed'
          | 'success'
          | 'paid_manually'
          | 'return_failed';
        meta: {
          amount?: {
            name?: string;
            value?: number;
            currency?: string;
            [k: string]: unknown;
          }[];
          supporting_doc?: {
            id?: string;
            link?: string;
            [k: string]: unknown;
          }[];
          payroll_executed_employees?: number;
          payroll_executed_employees_pending?: number;
          payroll_executed_employees_total?: number;
          pt_payment_employee_count?: number;
          mark_as_paid?: boolean;
          is_over_due?: boolean;
          due_date: string;
          payment_completion_expected_by?: string | null;
          payment_made_on?: string | null;
          display_name: string;
          message?: string;
          manual_payment_outside_payroll?: boolean | null;
          [k: string]: unknown;
        };
        [k: string]: unknown;
      };
    };
    [k: string]: unknown;
  };
  [k: string]: unknown;
}

export default <JSONSchema4>{
  type: 'object',
  properties: {
    payroll_month: { type: 'string' },
    actions: {
      type: 'object',
      properties: {
        'run-payroll': {
          type: 'object',
          properties: {
            status: {
              enum: [
                'completely_executed',
                'partially_executed',
                'finalized',
                'not_initiated',
                'pending',
                'processing',
              ],
            },
            meta: {
              type: 'object',
              properties: {
                last_payroll_executed: { type: ['null', 'string'] },
                payroll_executed_employees: { type: 'number' },
                payroll_executed_employees_pending: { type: 'number' },
                payroll_executed_employees_total: { type: 'number' },
                display_name: { type: 'string' },
                message: { type: 'string' },
              },
              required: ['display_name'],
            },
          },
          required: ['status', 'meta'],
        },
        'pay-pt': {
          type: 'object',
          patternProperties: {
            '^[a-zA-Z ]+$': {
              type: 'object',
              properties: {
                status: {
                  enum: [
                    'in_progress',
                    'not_initiated',
                    'failed',
                    'success',
                    'paid_manually',
                    'return_failed',
                  ],
                },
                meta: {
                  type: 'object',
                  properties: {
                    amount: {
                      type: 'array',
                      items: {
                        type: 'object',
                        properties: {
                          name: { type: 'string' },
                          value: { type: 'number' },
                          currency: { type: 'string' },
                        },
                      },
                    },
                    supporting_doc: {
                      type: 'array',
                      items: {
                        type: 'object',
                        properties: { id: { type: 'string' }, link: { type: 'string' } },
                      },
                    },
                    payroll_executed_employees: { type: 'number' },
                    payroll_executed_employees_pending: { type: 'number' },
                    payroll_executed_employees_total: { type: 'number' },
                    pt_payment_employee_count: { type: 'number' },
                    mark_as_paid: { type: 'boolean' },
                    is_over_due: { type: 'boolean' },
                    due_date: { type: 'string' },
                    payment_completion_expected_by: { type: ['string', 'null'] },
                    payment_made_on: { type: ['string', 'null'] },
                    display_name: { type: 'string' },
                    message: { type: 'string' },
                    manual_payment_outside_payroll: { type: ['boolean', 'null'] },
                  },
                  required: ['display_name', 'due_date'],
                },
              },
              required: ['status', 'meta'],
            },
          },
        },
      },
      required: ['run-payroll', 'pay-pt'],
    },
  },
  required: ['payroll_month', 'actions'],
};
