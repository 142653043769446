import createSalaryComponent from './mutations/createSalaryComponent';
import setSalaryComponentStatus from './mutations/setSalaryComponentStatus';
import updateAdditions from './mutations/updateAdditions';
import updateEmployeesAdditionsDeductionsLop from './mutations/updateEmployeesAdditionsDeductionsLop';
import { updateFnfDetails } from './mutations/updateFnfDetails';
import updateSalaryComponent from './mutations/updateSalaryComponent';
import getEmployeeAdditionsDeductions from './queries/getEmployeeAdditionsDeductions';
import { getFnfDetails } from './queries/getFnfDetails';
import { getDismissalDate } from './queries/getFnfDismissalDate';
import getSalaryComponent from './queries/getSalaryComponent';
import listAdditions from './queries/listAdditions';
import listDeductions from './queries/listDeductions';
import listSalaryComponents from './queries/listSalaryComponents';

const mutations = {
  setSalaryComponentStatus,
  updateSalaryComponent,
  createSalaryComponent,
  updateAdditions,
  updateFnfDetails,
  updateEmployeesAdditionsDeductionsLop,
};

const queries = {
  listSalaryComponents,
  getSalaryComponent,
  listAdditions,
  getFnfDetails,
  listDeductions,
  getEmployeeAdditionsDeductions,
  getDismissalDate,
};

const exports = {
  mutations,
  queries,
};

export default exports;
