import { gql } from 'graphql-request';
import { graphQLApi } from 'utils/Api';
import { BulkResumePayrollResponse } from '../types';

const bulkResumePayrollMutation = gql`
  mutation PayrollBulkResume($payroll_month: String!, $ids: [PositiveInt]!) {
    payrollBulkResume(payroll_month: $payroll_month, ids: $ids) {
      code
      data {
        success
      }
      message
      success
    }
  }
`;

const bulkResumePayroll = (queryVariables: { payroll_month: String; ids: number[] }) => {
  return graphQLApi<BulkResumePayrollResponse, { payroll_month: String; ids: number[] }>({
    query: bulkResumePayrollMutation,
    queryVariables,
  });
};

export default bulkResumePayroll;
