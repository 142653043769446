import { OrganizationContract, OrganizationSchema } from 'schemas/OrganizationListSchema';
import SuccessResponseSchema, {
  SuccessResponseSchemaContract,
} from 'schemas/SuccessResponseSchema';
import { getApi, postApi } from 'utils/Api';

const organization = {
  fetchList: () => {
    return getApi<OrganizationContract[]>({
      url: '/organization-list',
      responseSchema: OrganizationSchema,
    });
  },
  switchOrganization: (orgId: number) => {
    return postApi<SuccessResponseSchemaContract, { org_id: number }>({
      url: '/switch-organization',
      requestData: { org_id: orgId },
      responseSchema: SuccessResponseSchema,
    });
  },
};

export default organization;
