export const GSTIN_REGEX = '^[0123][0-9][A-Za-z]{5}[0-9]{4}[A-Za-z][A-Za-z0-9]{3}$';
export const PAN_REGEX = '^[A-Z]{3}[ABCFGHLJPTF]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}$';
export const BUSINES_PAN_REGEX =
  '^[A-Za-z]{3}[CcHhFfAaTtBbLlJjGg]{1}[A-Za-z]{1}[0-9]{4}[A-Za-z]{1}$';
export const TAN_REGEX = '^[A-Z|a-z]{4}[0-9]{5}[A-Z|a-z]$';
export const EMAIL_REGEX =
  "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$";
export const YMD_DATE_REGEX = '^\\d{4}-\\d{2}-\\d{2}$';
export const PHONE_NUMBER_REGEX = '^[1-9]\\d{9}$';
export const DECIMAL_REGEX = '^\\d{0,2}(\\.\\d{0,2}){0,1}$';
export const NUMERIC_REGEX = '^[0-9]\\d{0,6}$';
export const TWO_DIGIT_REGEX = '^[0-9]\\d{0,2}$';
export const NUMERIC_REGEX_WITH_SIGN = /^[+-]?[0-9]\d*(\.\d+)?$/;
export const CIN_REGEX = /^([LUu]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/;
export const LLPIN_REGEX = /^[A-Z]{3}-[0-9]{4}$/;
export const PINCODE_REGEX = /^[1-9][0-9]{5}$/;
export const OTP_REGEX = /^[0-9]{6}$/;
export const NUMBER_REGEX = '^\\d*(\\.\\d{0,2}){0,1}$';
