import {
  AutoComplete,
  AutoCompleteProps,
  Box,
  Dropdown,
  DropdownFooter,
  DropdownOverlay,
} from '@razorpay/blade/components';
import { useDebouncedValue } from 'hooks/useDebouncedValue';
import React, { useEffect, useMemo, useState } from 'react';
import SearchResultsView from './components/SearchResultsView';

type AutocompleteSelectiveProps = Omit<AutoCompleteProps, 'onChange' | 'value'>;

type AutoCompletePropsV2<T> = AutocompleteSelectiveProps & {
  options: T[];
  value?: string | null;
  onChange?: (item: T) => void;
  onClearSelection?: () => void;
  itemToLabel: (item: T) => string;
  itemToKey: (item: T) => string;
  itemToDescription?: (item: T) => string;
  footer?: React.ReactNode;
};

const AutoCompleteV2 = <T,>({
  options,
  value,
  onChange,
  onClearSelection,
  itemToLabel,
  itemToKey,
  itemToDescription,
  footer,
  ...bladeAutoCompletePropsV2
}: AutoCompletePropsV2<T>) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedValue, setSelectedValue] = useState<string | undefined | null>(value);

  const { debouncedValue: search } = useDebouncedValue(searchTerm, 200);

  // Handle static options
  const filteredOptions = useMemo(() => {
    if (!options) return [];
    else if (search) {
      return options.filter((item) =>
        itemToLabel(item)?.toLowerCase().includes(search.toLowerCase()),
      );
    } else {
      return options;
    }
  }, [options, search, itemToLabel]);

  const handleOnChange: AutoCompleteProps['onInputValueChange'] = (e) => {
    const newValue = e.value;
    setSearchTerm(newValue as string);
    if (!newValue) {
      onClearSelection?.();
    }
  };

  const handleItemSelect = (item: T) => {
    onChange?.(item);
    setSelectedValue(itemToKey(item));
  };

  const handleDropdownClose = (isOpen: boolean) => {
    if (!isOpen) {
      if (selectedValue) {
        const internalVal = searchItems.find((e) => itemToKey(e) === selectedValue);

        setSearchTerm(internalVal ? itemToLabel(internalVal) : '');
      } else {
        setSearchTerm('');
      }
    }
  };

  const searchItems = useMemo(() => {
    return filteredOptions;
  }, [filteredOptions]);

  useEffect(() => {
    setSelectedValue(value);
    const internalVal = options?.find((e) => itemToKey(e) === value);
    if (internalVal) {
      setSearchTerm(itemToLabel(internalVal));
    } else {
      setSearchTerm('');
    }
  }, [value, options, itemToKey, itemToLabel]);

  return (
    <Box position="relative">
      <Dropdown onOpenChange={handleDropdownClose}>
        <AutoComplete
          label=""
          value={selectedValue ? selectedValue : undefined}
          inputValue={searchTerm}
          onInputValueChange={handleOnChange}
          onChange={(e) => {
            const val = e.values[0];
            setSelectedValue(val);
          }}
          filteredValues={searchItems.map((e) => itemToKey(e))}
          {...bladeAutoCompletePropsV2}
        />

        <DropdownOverlay zIndex={9999999} width={'380px'}>
          <SearchResultsView
            searchItems={searchItems}
            onSelectItem={handleItemSelect}
            itemToLabel={itemToLabel}
            itemToKey={itemToKey}
            itemToDescription={itemToDescription}
          />
          {footer ? <DropdownFooter>{footer}</DropdownFooter> : <></>}
        </DropdownOverlay>
      </Dropdown>
    </Box>
  );
};

export default AutoCompleteV2;
