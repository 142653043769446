import { postApi } from 'utils/Api';
import SuccessResponseSchema from '../schemas/SuccessResponseSchema';

type QueryResponse = {
  prompt: string;
  response: string;
  chatId: string;
};

export type QueryPayload = {
  query: string;
  userId: string;
  payrollMonth: string;
  chatId?: string;
};

type FeedbackPayload = {
  referenceId: string;
  rating: string;
  topic: string;
  feedbackCategory?: string;
  feedback?: string;
  feedbackId?: number;
};

type FeedbackResponse = {
  feedback: {
    reference_id: string;
    topic: string;
    rating: string;
    feedback_category: string;
    feedback: string;
    updated_at: string;
    created_at: string;
    id: number;
  };
  message: string;
  success: boolean;
};

const apis = {
  getAnswer: (data: QueryPayload) => {
    return postApi<QueryResponse, QueryPayload>({
      url: '/payslip/answer-payslip-query',
      requestData: data,
      responseSchema: {},
    });
  },
  saveRating: (data: FeedbackPayload) => {
    return postApi<FeedbackResponse, FeedbackPayload>({
      url: '/save-user-rating',
      requestData: data,
      responseSchema: SuccessResponseSchema,
    });
  },
};

export default apis;
