import { CheckIcon, CloseIcon, IconSize, TargetIcon, Theme } from '@razorpay/blade/components';
import React from 'react';
import styled from 'styled-components';

type Status = 'error' | 'current' | 'success' | 'default';

export type StatusIconProps = {
  status?: Status;
  size?: IconSize;
};

const statusToBackgroundColor: Record<Status, (theme: Theme) => string> = {
  error: (theme) => theme.colors.feedback.background.negative.subtle,
  current: (theme) => theme.colors.surface.background.primary.subtle,
  success: (theme) => theme.colors.surface.background.sea.subtle,
  default: (theme) => theme.colors.interactive.background.gray.highlighted,
};

const StatusIconContainer = styled.div<StatusIconProps>`
  height: ${({ size }) => (size === 'small' ? '12px' : '16px')};
  width: ${({ size }) => (size === 'small' ? '12px' : '16px')};
  background-color: ${({ status = 'default', theme }) => statusToBackgroundColor[status](theme)};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

export const StatusIcon = ({ status = 'default', size = 'small' }: StatusIconProps) => {
  const statusToIcon = {
    error: <CloseIcon size={size} color="feedback.icon.negative.intense" />,
    current: <TargetIcon size={size} color="surface.icon.primary.normal" />,
    success: <CheckIcon size={size} color="feedback.icon.positive.intense" />,
    default: <TargetIcon size={size} color="interactive.icon.gray.disabled" />,
  };

  return <StatusIconContainer status={status}>{statusToIcon[status]}</StatusIconContainer>;
};
