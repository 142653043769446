import {
  PayrollCreateOrUpdateSalaryComponentVariables,
  PayrollUpdateSalaryComponentVariables,
} from 'api/gql-utils/salaryComponents/types';
import {
  DeductionType,
  DeductionsComponentType,
  EarningPayType,
  EarningsComponentType,
  TaxBehaviour,
} from 'components/SalaryComponents/types';
import { basicAndDaComponents, deductionsTaxSections, earningsTaxSections } from './constants';
import { DeductionsFormData, EarningsFormData } from './types';
import { capitalizeAllWordsInString } from 'utils/Strings';

export const checkIsDeductionTypeAdhoc = (selectedDeductionType?: DeductionType): boolean =>
  selectedDeductionType === 'ADHOC';
export const checkIsDeductionTypeRecurring = (selectedDeductionType?: DeductionType): boolean =>
  selectedDeductionType === 'RECURRING';

export const checkIsDeductingFromNetPay = (selectedDeductFromValue?: string): boolean =>
  selectedDeductFromValue === 'NET_PAY';

export const showDeductionsTaxabilitySection = (formData: Partial<DeductionsFormData>): boolean => {
  return (
    checkIsDeductionTypeRecurring(formData.deductionType) ||
    (checkIsDeductionTypeAdhoc(formData.deductionType) &&
      checkIsDeductingFromNetPay(formData.deductFrom))
  );
};
export const earningsExemptionDisplayText = (
  exemptionSection?: TaxBehaviour['taxExemptionSection'],
) => {
  return exemptionSection
    ? earningsTaxSections.find((value) => value.key === exemptionSection)?.label
    : '';
};

export const deductionsExemptionDisplayText = (
  exemptionSection?: TaxBehaviour['taxExemptionSection'],
) => {
  return exemptionSection
    ? deductionsTaxSections.find((value) => value.key === exemptionSection)?.label
    : '';
};

export const checkIsPayFrequencyAdhoc = (selectedPayFrequency?: EarningPayType): boolean =>
  selectedPayFrequency === 'adhoc';
export const checkIsPayFrequencyMonthly = (selectedPayFrequency?: EarningPayType): boolean =>
  selectedPayFrequency === 'fixed';
export const checkIsPayFrequencyVariable = (selectedPayFrequency?: EarningPayType): boolean =>
  selectedPayFrequency === 'variable';

export const checkIsComponentNameBasicOrDa = (name?: string): boolean =>
  name ? basicAndDaComponents.includes(name) : false;

export const isTaxExempt = (
  taxExemptInOldRegime?: boolean,
  taxExemptInNewRegime?: boolean,
): boolean => {
  return Boolean(taxExemptInOldRegime) || Boolean(taxExemptInNewRegime);
};

export const convertFormDataToEarningSalaryComponentPayload = (
  formData: Partial<EarningsFormData>,
  id?: string,
): PayrollCreateOrUpdateSalaryComponentVariables => {
  const taxBehaviour: Partial<TaxBehaviour> = {
    taxExemptInOldRegime: Boolean(formData.taxExemptInOldRegime),
    taxExemptInNewRegime: Boolean(formData.taxExemptInNewRegime),
  };

  const isTaxExempted = isTaxExempt(formData.taxExemptInOldRegime, formData.taxExemptInNewRegime);

  if (isTaxExempted && Array.isArray(formData.taxExemptionSection)) {
    taxBehaviour.taxExemptionSection = formData.taxExemptionSection[0];
  }

  if (formData.payType !== 'fixed') {
    taxBehaviour.taxDeductionBehaviour = formData.taxDeductionBehaviour;
  }

  return {
    id,
    category: 'EARNINGS_CATEGORY',
    source: 'USER',
    name: formData.name!,
    description: formData.description!,
    isActive: true,
    settings: {
      behaviour: {
        arrearBehaviour: {
          considerInArrear: Boolean(formData.considerInArrear),
          allowNegative: false,
        },
        esiBehaviour: {
          includeInEsiWages: Boolean(formData.includeInEsiWages),
        },
        lwfBehaviour: {
          includeInLwfWages: Boolean(formData.includeInLwfWages),
        },
        pfBehaviour: {
          includeInPfWages: Boolean(formData.includeInPfWages),
        },
        ptBehaviour: {
          includeInPtWages: Boolean(formData.includeInPtWages),
        },
        prorationBehaviour: {
          supportProration: Boolean(formData.supportProration),
        },
        taxBehaviour: taxBehaviour as TaxBehaviour,
        displayBehaviour: {
          visibleToEmployee: true,
          displayName: formData.displayName,
        },
      },
      earningDetails: {
        payType: formData.payType || 'adhoc',
      },
    },
  };
};

export const convertFormDataToEarningSalaryComponentUpdatePayload = (
  formData: Partial<EarningsFormData>,
  id: string,
): PayrollUpdateSalaryComponentVariables => {
  const taxBehaviour: Partial<TaxBehaviour> = {
    taxExemptInOldRegime: Boolean(formData.taxExemptInOldRegime),
    taxExemptInNewRegime: Boolean(formData.taxExemptInNewRegime),
  };

  const isTaxExempted = formData.taxExemptInOldRegime || formData.taxExemptInNewRegime;

  if (isTaxExempted && Array.isArray(formData.taxExemptionSection)) {
    taxBehaviour.taxExemptionSection = formData.taxExemptionSection[0];
  }

  if (formData.payType !== 'fixed') {
    taxBehaviour.taxDeductionBehaviour = formData.taxDeductionBehaviour;
  }

  return {
    id,
    category: 'EARNINGS_CATEGORY',
    source: 'USER',
    name: formData.name!,
    description: formData.description!,
    isActive: true,
    payType: formData.payType,
    settings: {
      arrearBehaviour: {
        considerInArrear: Boolean(formData.considerInArrear),
        allowNegative: false,
      },
      esiBehaviour: {
        includeInEsiWages: Boolean(formData.includeInEsiWages),
      },
      lwfBehaviour: {
        includeInLwfWages: Boolean(formData.includeInLwfWages),
      },
      pfBehaviour: {
        includeInPfWages: Boolean(formData.includeInPfWages),
      },
      ptBehaviour: {
        includeInPtWages: Boolean(formData.includeInPtWages),
      },
      prorationBehaviour: {
        supportProration: Boolean(formData.supportProration),
      },
      taxBehaviour: taxBehaviour as TaxBehaviour,
      displayBehaviour: {
        visibleToEmployee: true,
        displayName: formData.displayName,
      },
    },
  };
};

export const convertSalaryComponentToEarningFormData = (
  salaryComponent: EarningsComponentType,
): EarningsFormData => {
  return {
    source: 'USER',
    name: capitalizeAllWordsInString(salaryComponent.name),
    description: salaryComponent.description!,
    isPredefined: salaryComponent.isPredefined,
    considerInArrear: Boolean(salaryComponent.settings.behaviour.arrearBehaviour?.considerInArrear),
    includeInEsiWages: Boolean(salaryComponent.settings.behaviour.esiBehaviour?.includeInEsiWages),
    includeInLwfWages: Boolean(salaryComponent.settings.behaviour.lwfBehaviour?.includeInLwfWages),
    includeInPfWages: Boolean(salaryComponent.settings.behaviour.pfBehaviour?.includeInPfWages),
    includeInPtWages: Boolean(salaryComponent.settings.behaviour.ptBehaviour?.includeInPtWages),
    supportProration: Boolean(
      salaryComponent.settings.behaviour.prorationBehaviour?.supportProration,
    ),
    taxExemptInNewRegime: Boolean(
      salaryComponent.settings.behaviour.taxBehaviour?.taxExemptInNewRegime,
    ),
    taxExemptInOldRegime: Boolean(
      salaryComponent.settings.behaviour.taxBehaviour?.taxExemptInOldRegime,
    ),
    taxDeductionBehaviour: salaryComponent.settings.behaviour.taxBehaviour?.taxDeductionBehaviour,
    taxExemptionSection: salaryComponent.settings.behaviour.taxBehaviour?.taxExemptionSection
      ? [salaryComponent.settings.behaviour.taxBehaviour.taxExemptionSection]
      : undefined,

    displayName: salaryComponent.settings.behaviour.displayBehaviour?.displayName,

    payType: salaryComponent.settings.earningDetails.payType,
  };
};

export const convertSalaryComponentToDeductionsFormData = (
  salaryComponent: DeductionsComponentType,
): DeductionsFormData => {
  return {
    source: 'USER',
    name: capitalizeAllWordsInString(salaryComponent.name),
    description: salaryComponent.description!,
    isPredefined: salaryComponent.isPredefined,
    considerInArrear: Boolean(salaryComponent.settings.behaviour.arrearBehaviour?.considerInArrear),
    includeInEsiWages: Boolean(salaryComponent.settings.behaviour.esiBehaviour?.includeInEsiWages),
    includeInLwfWages: Boolean(salaryComponent.settings.behaviour.lwfBehaviour?.includeInLwfWages),
    includeInPfWages: Boolean(salaryComponent.settings.behaviour.pfBehaviour?.includeInPfWages),
    includeInPtWages: Boolean(salaryComponent.settings.behaviour.ptBehaviour?.includeInPtWages),
    supportProration: Boolean(
      salaryComponent.settings.behaviour.prorationBehaviour?.supportProration,
    ),
    taxExemptInNewRegime: Boolean(
      salaryComponent.settings.behaviour.taxBehaviour?.taxExemptInNewRegime,
    ),
    taxExemptInOldRegime: Boolean(
      salaryComponent.settings.behaviour.taxBehaviour?.taxExemptInOldRegime,
    ),
    taxExemptionSection: salaryComponent.settings.behaviour.taxBehaviour?.taxExemptionSection
      ? [salaryComponent.settings.behaviour.taxBehaviour.taxExemptionSection]
      : undefined,
    displayName: salaryComponent.settings.behaviour.displayBehaviour?.displayName,
    deductionType: salaryComponent.settings.deductionDetails.deductionType,
    deductFrom: salaryComponent.settings.deductionDetails.deductFrom,
  };
};

export const convertFormDataToDeductionSalaryComponentPayload = (
  formData: Partial<DeductionsFormData>,
  id?: string,
): PayrollCreateOrUpdateSalaryComponentVariables => {
  const taxBehaviour: Partial<TaxBehaviour> = {
    taxExemptInOldRegime: Boolean(formData.taxExemptInOldRegime),
    taxExemptInNewRegime: Boolean(formData.taxExemptInNewRegime),
  };
  if (Array.isArray(formData.taxExemptionSection)) {
    taxBehaviour.taxExemptionSection = formData.taxExemptionSection[0];
  }

  return {
    id,
    category: 'DEDUCTIONS_CATEGORY',
    name: formData.name!,
    source: 'USER',
    description: formData.description!,
    isActive: true,
    settings: {
      behaviour: {
        arrearBehaviour: {
          considerInArrear: Boolean(formData.considerInArrear),
          allowNegative: false,
        },
        esiBehaviour: {
          includeInEsiWages: Boolean(formData.includeInEsiWages),
        },
        lwfBehaviour: {
          includeInLwfWages: Boolean(formData.includeInLwfWages),
        },
        pfBehaviour: {
          includeInPfWages: Boolean(formData.includeInPfWages),
        },
        ptBehaviour: {
          includeInPtWages: Boolean(formData.includeInPtWages),
        },
        prorationBehaviour: {
          supportProration: Boolean(formData.supportProration),
        },
        taxBehaviour: taxBehaviour as TaxBehaviour,
        displayBehaviour: {
          visibleToEmployee: true,
          displayName: formData.displayName,
        },
      },
      deductionDetails: {
        deductionType: formData.deductionType || 'RECURRING',
        deductFrom: formData.deductFrom || 'GROSS_PAY',
      },
    },
  };
};

export const convertFormDataToDeductionSalaryComponentUpdatePayload = (
  formData: Partial<DeductionsFormData>,
  id: string,
): PayrollUpdateSalaryComponentVariables => {
  const taxBehaviour: Partial<TaxBehaviour> = {
    taxExemptInOldRegime: Boolean(formData.taxExemptInOldRegime),
    taxExemptInNewRegime: Boolean(formData.taxExemptInNewRegime),
  };

  if (Array.isArray(formData.taxExemptionSection)) {
    taxBehaviour.taxExemptionSection = formData.taxExemptionSection[0];
  }

  return {
    id,
    category: 'DEDUCTIONS_CATEGORY',
    name: formData.name!,
    source: 'USER',
    description: formData.description!,
    isActive: true,
    settings: {
      arrearBehaviour: {
        considerInArrear: Boolean(formData.considerInArrear),
        allowNegative: false,
      },
      esiBehaviour: {
        includeInEsiWages: Boolean(formData.includeInEsiWages),
      },
      lwfBehaviour: {
        includeInLwfWages: Boolean(formData.includeInLwfWages),
      },
      pfBehaviour: {
        includeInPfWages: Boolean(formData.includeInPfWages),
      },
      ptBehaviour: {
        includeInPtWages: Boolean(formData.includeInPtWages),
      },
      prorationBehaviour: {
        supportProration: Boolean(formData.supportProration),
      },
      taxBehaviour: taxBehaviour as TaxBehaviour,
      displayBehaviour: {
        displayName: formData.displayName,
      },
    },
  };
};
