import { Box, Divider, Text } from '@razorpay/blade/components';
import React from 'react';
import { StatusIcon } from '.';
import { useWizardInfo } from '../providers/WizardInfoProvider';

type WizardProgressViewProps = {
  steps: { label: string; position: number }[];
};

export const WizardProgressView = ({ steps }: WizardProgressViewProps) => {
  const { currentStep, totalSteps, stepStatus } = useWizardInfo();
  return (
    <Box display="flex" alignItems="center" gap="spacing.1">
      {steps.map((step, index) => (
        <React.Fragment key={step.label}>
          <Box display="flex" alignItems="center" gap="6px">
            <StatusIcon
              status={currentStep === index ? 'current' : stepStatus[step.position]}
              size="xsmall"
            />
            <Text color="surface.text.gray.muted">{step.label}</Text>
          </Box>
          {index < totalSteps - 1 && <Divider dividerStyle="dashed" width="32px" />}
        </React.Fragment>
      ))}
    </Box>
  );
};
