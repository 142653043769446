import { gql } from 'graphql-request';
import { graphQLApi } from 'utils/Api';
import { GetEmployeeAdditionsDeductionsQueryVariables, ListAdditionsResponse } from '../types';

const listAdditionsQuery = gql`
  query PayrollListAdditions($userId: String!, $effectiveFrom: String!, $effectiveTo: String!) {
    payrollListAdditions(userId: $userId, effectiveFrom: $effectiveFrom, effectiveTo: $effectiveTo) {
      code
      data {
        data {
          id
          componentName
          componentId
          userId
          type
          amount
          effectiveFrom
          effectiveTo
        }
        source
      }
      message
      success
    }
  }
`;

const listAdditions = (queryVariables: GetEmployeeAdditionsDeductionsQueryVariables) => {
  return graphQLApi<ListAdditionsResponse, GetEmployeeAdditionsDeductionsQueryVariables>({
    query: listAdditionsQuery,
    queryVariables,
  });
};

export default listAdditions;
