import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import api from '../../../api';
import { fetchNotifications, markNotificationsRead } from '../../../reducers/notifications';
import '../tailwind.css';
import { NotificationsList } from './components/NotificationList';
import {
  BellIcon,
  Dropdown,
  DropdownButton,
  DropdownOverlay,
  useTheme,
  BottomSheet,
  BottomSheetBody,
  BottomSheetHeader,
  Box,
  Button,
} from '@razorpay/blade/components';
import useReduxSelector from 'utils/useReduxSelector';

export function Notification() {
  const dispatch = useDispatch();
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
  const { platform } = useTheme();
  const isMobile = platform === 'onMobile';
  const notifications = useReduxSelector((state) => state.notification);

  // let hasUnreadNotifications = false;
  // const unreadQuery = useQuery('checkRead', () => {
  //   if(id) {
  //     return api.notifications.fetchUnreadNotifications(id);
  //   }
  // })

  // if(unreadQuery.status === 'success' && unreadQuery.data) {
  //   if(unreadQuery.data.unread === true) {

  //     hasUnreadNotifications = true;
  //   }
  // }

  const handleMarkNotificationsAsRead = () => {
    let ids: string[] = [];

    Object.keys(notifications.data).forEach((month) => {
      notifications.data[month].forEach((individual) => {
        if (individual.readStatus === false) {
          ids.push(individual.id);
        }
      });
    });

    ids.length > 0 && dispatch(markNotificationsRead(ids));
  };

  async function sendNotificationInstrumentationEvent() {
    try {
      const response = await api.analytics.push({
        eventName: 'notifications.clicked',
        eventType: 'viewed',
      });
    } catch (error) {
      console.log(error);
    }
  }

  const handleDrawerStateChange = (isOpen: boolean) => {
    if (isOpen) {
      sendNotificationInstrumentationEvent();
      handleMarkNotificationsAsRead();
    }
  };

  useEffect(() => {
    dispatch(fetchNotifications());
  }, []);

  if (isMobile) {
    return (
      <Box>
        <Button
          icon={BellIcon}
          variant="tertiary"
          accessibilityLabel="notifications"
          onClick={() => {
            setIsBottomSheetOpen((isOpen) => !isOpen);
          }}
        />
        <BottomSheet
          isOpen={isBottomSheetOpen}
          onDismiss={() => {
            handleDrawerStateChange(false);
            setIsBottomSheetOpen(false);
          }}>
          <BottomSheetHeader title="Notifications" />
          <BottomSheetBody>
            <NotificationsList />
          </BottomSheetBody>
        </BottomSheet>
      </Box>
    );
  }
  return (
    <Dropdown onOpenChange={handleDrawerStateChange}>
      <DropdownButton icon={BellIcon} variant="tertiary" accessibilityLabel="notifications" />
      <DropdownOverlay>
        <NotificationsList />
      </DropdownOverlay>
    </Dropdown>
  );
}

export default function NotificationContainer() {
  return <Notification />;
}
