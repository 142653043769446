import { Alert, Box } from '@razorpay/blade/components';
import React from 'react';
import { parseError } from 'utils/Api';
import { AppError } from 'utils/AppError';

interface WizardErrorProps {
  error?: Error | AppError | unknown;
  onRetry: () => void;
}

const WizardError: React.FC<WizardErrorProps> = ({ error, onRetry, children }) => {
  // Type guard function to check if error is AppError
  const isAppError = (err: unknown): err is AppError => {
    return err instanceof AppError;
  };

  // Type guard function to check if error is Error
  const isError = (err: unknown): err is Error => {
    return err instanceof Error;
  };

  const errorMessage = isAppError(error)
    ? parseError(error)
    : isError(error)
    ? error.message
    : 'Unable to load details. Please try again or contact support if the issue persists.';

  if (error) {
    return (
      <Box display="grid" placeItems="center" width="100%" padding="spacing.10">
        <Alert
          title="Error loading salary component details"
          description={errorMessage}
          color="negative"
          isDismissible={false}
          actions={{
            primary: {
              text: 'Retry',
              onClick: onRetry,
            },
          }}
        />
      </Box>
    );
  }
  return <>{children}</>;
};

export { WizardError };
