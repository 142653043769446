import styled from 'styled-components';

export const LogoImage = styled.img`
  max-height: 50px;
  margin-left: 20px;
  max-width: 200px;

  @media (max-width: 768px) {
    max-width: 160px;
    max-height: 40px;
    margin-left: 0px;
  }
`;
