import { gql } from 'graphql-request';
import { GetFnfResponseSchemaContract } from 'schemas/GetFnfResponseSchema';
import { graphQLApi } from 'utils/Api';
import { generateUserId } from 'utils/commons';
import { paiseAmountToRupee } from 'utils/Numbers';
import {
  AssignmentResponseData,
  GetLopDataItem,
  LopSource,
  PayrollGetFnfDataResponse,
  PayrollGetFnfDataVariables,
} from '../types';
import { DeductFromLabelToNumberMap, DeductionType } from 'components/RunPayroll/utils/types';
import { DeductionsComponentType, EarningsComponentType } from 'components/SalaryComponents/types';

const getFnfDetailsQuery = gql`
  query PayrollGetFnfData($userId: String!, $deactivateDate: String!) {
    payrollGetFnfData(userId: $userId, deactivateDate: $deactivateDate) {
      data {
        additions {
          id
          componentId
          componentName
          userId
          amount
          effectiveFrom
          effectiveTo
          type
          componentDetails {
            id
            name
            description
            defaultFormula
            category
            settings {
              behaviour {
                pfBehaviour {
                  includeInPfWages
                }
                ptBehaviour {
                  includeInPtWages
                }
                esiBehaviour {
                  includeInEsiWages
                }
                lwfBehaviour {
                  includeInLwfWages
                }
                taxBehaviour {
                  taxExemptionSection
                  taxDeductionBehaviour
                  taxExemptInNewRegime
                  taxExemptInOldRegime
                }
                arrearBehaviour {
                  considerInArrear
                }
                prorationBehaviour {
                  supportProration
                }
                displayBehaviour {
                  showZero
                  visibleToEmployee
                  displayName
                }
              }
              earningDetails {
                payType
              }
            }
            isPredefined
            isActive
            source
          }
        }
        deductions {
          id
          componentId
          componentName
          userId
          amount
          effectiveFrom
          effectiveTo
          type
          componentDetails {
            id
            name
            description
            defaultFormula
            category
            settings {
              behaviour {
                pfBehaviour {
                  includeInPfWages
                }
                ptBehaviour {
                  includeInPtWages
                }
                esiBehaviour {
                  includeInEsiWages
                }
                lwfBehaviour {
                  includeInLwfWages
                }
                taxBehaviour {
                  taxExemptionSection
                  taxDeductionBehaviour
                  taxExemptInNewRegime
                  taxExemptInOldRegime
                }
                arrearBehaviour {
                  considerInArrear
                }
                prorationBehaviour {
                  supportProration
                }
                displayBehaviour {
                  showZero
                  visibleToEmployee
                  displayName
                }
              }
              deductionDetails {
                deductionType
                deductFrom
              }
            }
            isPredefined
            isActive
            source
          }
        }
        lopData {
          id
          peopleId
          organizationId
          payrollMonth
          lopDays
          source
          createdAt
          updatedAt
          deletedAt
        }
        userData {
          id
          name
          organizationId
          peopleId
          hireDate
          title
          deactivateDate
          location
          employeeId
          optionalUserData
          department
          idPrefix
          uiPreferences
          reasonForLeaving
        }
        userStatuses {
          usersStatus {
            userId
            status
          }
          isOrgFinal
        }
      }
      code
      success
      message
    }
  }
`;

const mapAdditions = (additions: AssignmentResponseData<EarningsComponentType>[]) => {
  return additions.map((addition) => ({
    name: addition.componentDetails.name,
    amount: paiseAmountToRupee(addition.amount),
    id: addition.componentDetails.id,
  }));
};

const mapDeductions = (deductions: AssignmentResponseData<DeductionsComponentType>[]) => {
  return deductions.map((deduction) => ({
    name: deduction.componentDetails.name,
    amount: paiseAmountToRupee(deduction.amount),
    deduct_from:
      DeductFromLabelToNumberMap[
        deduction.componentDetails.settings.deductionDetails.deductFrom ?? 'GROSS_PAY'
      ],
    id: deduction.componentDetails.id,
  }));
};

const mapLopSourceToDeductionType = (lopSource?: LopSource) => {
  switch (lopSource) {
    case 'manual':
      return DeductionType.TYPE_MANUAL;
    case 'manual-attendance':
      return DeductionType.TYPE_MANUAL_ATTENDANCE_LOP;
    case 'fnf':
      return DeductionType.TYPE_MANUAL;
    case 'auto-attendance':
      return DeductionType.TYPE_AUTO_ATTENDANCE_LOP;
    default:
      return DeductionType.TYPE_MANUAL;
  }
};

const mapLopDeduction = (lopData: GetLopDataItem[], userId: string) => {
  const userLopData = lopData.find((item) => item.peopleId === userId);
  return {
    amount: 0, // This needs to be calculated based on business logic
    lop_days: userLopData ? userLopData.lopDays.toString() : '0',
    type: mapLopSourceToDeductionType(userLopData?.source), // TODO: Update it from response once its available
  };
};

const transformToFnfResponseSchema = (
  response: PayrollGetFnfDataResponse,
  queryVariables: PayrollGetFnfDataVariables,
): GetFnfResponseSchemaContract => {
  const { userData, additions, deductions, lopData, userStatuses } =
    response.payrollGetFnfData.data;

  const userId = generateUserId(queryVariables.userId);
  const userStatus =
    userStatuses.usersStatus.find((status) => status.userId === userId)?.status || 'none';

  const payroll_status = {
    is_finalized: userStatus === 'finalized',
    is_skipped: userStatus === 'skipped',
    is_paid: userStatus === 'executed',
    is_finalization_in_progress: userStatus === 'created',
  };

  const is_fnf_processed = payroll_status.is_paid;

  return {
    is_dismissal_synced_by_hrms: false,
    is_fnf_processed,
    payroll_id: null,
    is_only_admin: false,
    show_bonus_banner: false,
    reason_for_leaving: userData.reasonForLeaving as string,
    loan_amount_required_for_full_recovery: null,
    deactivate_date: userData.deactivateDate as string,
    date_of_joining: userData.hireDate as string,
    net_pay_on_hold_from: null,
    personal_email_address: userData.optionalUserData?.personalEmailAddress as string,
    has_active_insurance: false,
    employee_name: userData.name as string,
    employee_id: userData.employeeId,
    employee_department: userData.department,
    employee_title: userData.title,
    leave_types: null,
    salary_advance: null,
    payroll_status,
    additions: mapAdditions(additions) || null,
    deductions: {
      non_lop_deductions: mapDeductions(deductions) || null,
      lop_deduction: mapLopDeduction(lopData, queryVariables.userId),
      total_deduction: 0, // This needs to be calculated based on all deductions
    },
    leave_encashments: null,
    gratuity: {
      gratuity_applicable: false,
      gratuity_amount: null,
    },
  };
};

export const getFnfDetails = async (queryVariables: PayrollGetFnfDataVariables) => {
  try {
    const response = await graphQLApi<PayrollGetFnfDataResponse, PayrollGetFnfDataVariables>({
      query: getFnfDetailsQuery,
      queryVariables,
    });

    return transformToFnfResponseSchema(response, queryVariables);
  } catch (error: unknown) {
    throw error;
  }
};
