import React from 'react';
import salarioLogo from 'assets/partners/salario-dashboard-logo.svg';
import payrollLogo from 'assets/payroll-logo.svg';
import payrollLogoLightTheme from 'assets/payroll-logo.png';
import { getStaticMediaUrl } from 'utils/Urls';
import { useTheme } from '@razorpay/blade/components';
import { useLocation } from 'react-router-dom';
import razorpayXLogo from 'assets/razorpay-x-logo.svg';
import { isCAPartnerPage } from '../utils';
import { LogoImage } from './styles';

const logoMap = {
  salario: salarioLogo,
};

export function OrgLogo({ logo }: { logo?: string | null }) {
  const location = useLocation();
  const hostName = window.location.hostname;
  const subDomain = hostName.split('.')[0] as keyof typeof logoMap;
  const { colorScheme } = useTheme();

  let logoToShow;
  let imageAltText = 'RazorpayX Payroll logo';
  if (isCAPartnerPage(location)) {
    logoToShow = getStaticMediaUrl(razorpayXLogo);
    imageAltText = 'RazorpayX logo';
  } else if (subDomain in logoMap) {
    logoToShow = getStaticMediaUrl(logoMap[subDomain]);
    imageAltText = 'Organization logo';
  } else if (logo) {
    logoToShow = '/download?bucket=org-logos&key=' + logo + '&toCache=1';
    imageAltText = 'Organization logo';
  } else {
    const payrollHeaderLogo = colorScheme === 'light' ? payrollLogoLightTheme : payrollLogo;
    logoToShow = getStaticMediaUrl(payrollHeaderLogo);
    imageAltText = 'RazorpayX Payroll logo';
  }

  return (
    <a href="/">
      <LogoImage src={logoToShow} alt={imageAltText} />
    </a>
  );
}

export default OrgLogo;
