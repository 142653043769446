import { Schema, Validator } from 'jsonschema';
import { ECustomErrors, EHttpStatusCode } from '../enums';
import { EMethod } from '../enums/EMethod';
import { IGetCallApiParams, IGraphQLCallApiParams, INonGetCallApiParams } from '../interfaces/IApi';
import { AppError, getAppErrorDetails } from './AppError';
import { getCsrfToken } from './PageVariables';
import { IAppErrorDetails } from 'utils/AppError';
import { capturePrometheusMetrics, Metrics } from './Lumberjack';
import { getRedactedPath } from './Urls';
import { captureErrorInSentry } from './sentry';
import { showErrorToastNotification } from './ToastEvents';
import request, { ClientError } from 'graphql-request';

function getUrl(url: string) {
  url = url.replace(/^[\s/]+/, '').replace(/[\s/]+$/, '');
  if (!url) {
    throw new Error('Please provide url');
  }
  if (url.includes('http')) {
    return url;
  }
  return `${window.location.origin}/v2/api/${url}`;
}

function validateJsonResponseAsContract<SuccessResponseContract>(
  schema: Schema,
  data: JSON | SuccessResponseContract,
) {
  const validator = new Validator();
  const result = validator.validate(data, schema);
  if (!result.valid) {
    capturePrometheusMetrics(Metrics.ERROR_COUNT, {
      pathname: getRedactedPath(window.location.pathname),
      source: 'response-validator',
    });
    captureErrorInSentry(new Error(result.toString()));
    throw new AppError({
      type: ECustomErrors.INVALID_SCHEMA,
      internalMessage: result.toString(),
      otherDetails: result,
    });
  }
  return data as SuccessResponseContract;
}

function prepareMethod(method: EMethod, fetchOptions: RequestInit = {}) {
  fetchOptions.method = method;
  return fetchOptions;
}

function prepareHeaders(fetchOptions: RequestInit = {}, optionalHeaders: any = {}) {
  const csrf = getCsrfToken();
  fetchOptions.headers = fetchOptions.headers || {
    Accept: 'application/json',
    credentials: 'same-origin',
    'Content-Type': 'application/json',
    csrf: csrf ? csrf : '',
    ...optionalHeaders,
  };
  return fetchOptions;
}

function prepareMultiPartFormHeaders(fetchOptions: RequestInit = {}) {
  const csrf = getCsrfToken();
  fetchOptions.headers = fetchOptions.headers || {
    Accept: 'application/json',
    credentials: 'same-origin',
    csrf: csrf ? csrf : '',
  };
  return fetchOptions;
}

async function callApi<SuccessResponseContract>(
  url: string,
  fetchOptions: RequestInit,
  responseSchema: Schema,
) {
  try {
    const response = await fetch(url, fetchOptions);
    let jsonResponse = await response.json();

    if (response.status === 401) {
      const currentURL = window.location.pathname + window.location.search;
      const loginURL = `/login?redirect=${encodeURIComponent(currentURL)}`;
      window.location.replace(loginURL);
      return {} as SuccessResponseContract;
    }

    // Capture error in prometheus whenever there is a non 200 response
    const shouldReportErrorMetric = response.status >= 500;
    if (shouldReportErrorMetric) {
      capturePrometheusMetrics(Metrics.ERROR_COUNT, {
        pathname: getRedactedPath(window.location.pathname),
        source: 'api-call',
        status: response.status.toString(),
      });
    }

    if (!response.ok) {
      const code = jsonResponse.code;
      if (code === 'UNKNOWN') {
        throw new AppError({
          internalMessage: `Api call ([${fetchOptions.method}] ${url}) error: [${response.status}]${response.statusText}`,
          messageForUser:
            response.status === EHttpStatusCode.BAD_REQUEST
              ? jsonResponse
              : 'Oops! something went wrong',
          otherDetails: {
            url,
            response,
            jsonResponse,
            method: fetchOptions.method,
          },
          type: response.status,
          code,
          errors: jsonResponse.errors,
        });
      } else {
        throw new AppError({
          internalMessage: `Api call ([${fetchOptions.method}] ${url}) error: [${response.status}]${response.statusText}`,
          messageForUser: jsonResponse.message || 'Something went wrong, please contact support',
          otherDetails: {
            url,
            response,
            jsonResponse,
            method: fetchOptions.method,
          },
          type: response.status,
          code,
          errors: jsonResponse.errors,
        });
      }
    }
    let reponseData = validateJsonResponseAsContract<SuccessResponseContract>(
      responseSchema,
      jsonResponse,
    );
    return reponseData;
  } catch (e) {
    if (e instanceof AppError) {
      console.error('API error:', e.getDetails());
      throw e;
    } else if (e instanceof Error) {
      const appError = new AppError({ internalMessage: e.message });
      console.error('API error:', appError.getDetails());
      throw appError;
    } else {
      throw new AppError({ internalMessage: 'Something went wrong' });
    }
  }
}

export const getApi = async <SuccessResponseContract>(params: IGetCallApiParams) => {
  let fetchOptions = prepareMethod(EMethod.GET);
  fetchOptions = prepareHeaders(fetchOptions, params.headers);

  return callApi<SuccessResponseContract>(getUrl(params.url), fetchOptions, params.responseSchema);
};

export const graphQLApi = async <
  SuccessResponseContract,
  RequestVariables extends Record<string, any>,
>(
  params: IGraphQLCallApiParams<RequestVariables>,
): Promise<SuccessResponseContract> => {
  try {
    const res = await request('/v2/api/graphql', params.query, params.queryVariables, {
      Accept: 'application/json',
      csrf: getCsrfToken() ?? '',
      ...params.headers,
    });

    return res as SuccessResponseContract;
  } catch (e) {
    if (e instanceof ClientError) {
      const errors = e.response.errors ?? [];
      // The error message would be in the errors field for error from graphql endpoints
      const errorString = Array.isArray(errors)
        ? errors.map((item) => item.message).join('\n')
        : '';

      // The error message would be in the extensions field for error from monolith endpoints
      const errorStringFromExtensions = Array.isArray(errors)
        ? errors
            .map((item) => item.extensions)
            .flatMap((e) => e.message)
            .join('\n')
        : '';

      throw new AppError({
        messageForUser: (errorString || errorStringFromExtensions) as string,
        code: (e.response.extensions as any)?.code,
        internalMessage: 'Something went wrong',
        type: e.response.status,
      });
    } else if (e instanceof Error) {
      const appError = new AppError({ internalMessage: e.message });
      console.error('API error:', appError.getDetails());
      throw appError;
    }
    throw e;
  }
};

export const putApi = async <SuccessResponseContract, RequestContract>(
  params: INonGetCallApiParams<RequestContract>,
): Promise<SuccessResponseContract> => {
  let fetchOptions = prepareMethod(EMethod.PUT);
  fetchOptions = prepareHeaders(fetchOptions, params.headers);
  fetchOptions.body = params.requestData ? JSON.stringify(params.requestData) : '{}';

  return callApi<SuccessResponseContract>(getUrl(params.url), fetchOptions, params.responseSchema);
};

export const patchApi = async <SuccessResponseContract, RequestContract>(
  params: INonGetCallApiParams<RequestContract>,
): Promise<SuccessResponseContract> => {
  let fetchOptions = prepareMethod(EMethod.PATCH);
  fetchOptions = prepareHeaders(fetchOptions, params.headers);
  fetchOptions.body = params.requestData ? JSON.stringify(params.requestData) : '{}';

  return callApi<SuccessResponseContract>(getUrl(params.url), fetchOptions, params.responseSchema);
};

function prepareBody<RequestContract>(body: RequestContract): string | FormData {
  if (body instanceof FormData) {
    return body ? body : new FormData();
  }
  return body ? JSON.stringify(body) : '{}';
}

/**
 * Usage example
 * postApi<SampleSchema,{hello: string}>({
    url: '/hello',
    method: Method.POST,
    requestData: {hello: "world"},
    responseSchema: sampleSchema
   })
 */

export const postApi = async <SuccessResponseContract, RequestContract>(
  params: INonGetCallApiParams<RequestContract>,
  contentType?: string,
): Promise<SuccessResponseContract> => {
  let fetchOptions = prepareMethod(EMethod.POST);
  fetchOptions =
    contentType === 'multipart'
      ? prepareMultiPartFormHeaders(fetchOptions)
      : prepareHeaders(fetchOptions, params.headers);
  fetchOptions.body = prepareBody(params.requestData);

  return callApi<SuccessResponseContract>(getUrl(params.url), fetchOptions, params.responseSchema);
};

export const deleteApi = async <SuccessResponseContract, RequestContract>(
  params: INonGetCallApiParams<RequestContract>,
): Promise<SuccessResponseContract> => {
  let fetchOptions = prepareMethod(EMethod.DELETE);
  fetchOptions = prepareHeaders(fetchOptions, params.headers);
  fetchOptions.body = params.requestData ? JSON.stringify(params.requestData) : '{}';

  return callApi<SuccessResponseContract>(getUrl(params.url), fetchOptions, params.responseSchema);
};

export const errorParser = (errorResponse: IAppErrorDetails): string[] => {
  const errors: string[] = [];

  if (Object.keys(errorResponse.errors || {}).length === 0) {
    errors.push(errorResponse.messageForUser);
  }

  for (const error in errorResponse.errors) {
    errors.push(...errorResponse.errors[error]);
  }

  return errors;
};

export const showApiErrorNotification = (
  error: unknown,
  notificationOpts?: {
    timeout?: number;
  },
) => {
  const errorMessage = errorParser(getAppErrorDetails(error))[0];

  showErrorToastNotification({
    text: errorMessage,
    timeout: notificationOpts?.timeout ?? 5000,
  });
};

export const parseError = (error: typeof AppError | AppError) => {
  return errorParser(getAppErrorDetails(error)).join(' ');
};
