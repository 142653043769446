import bulkResumePayroll from './bulkResumePayroll';
import bulkSkipPayroll from './bulkSkipPayroll';
import skipAllExceptThis from './skipAllExceptThis';

const runpayrollMutations = {
  bulkSkipPayroll,
  bulkResumePayroll,
  skipAllExceptThis,
};

export default runpayrollMutations;
