import { gql } from 'graphql-request';
import { graphQLApi } from 'utils/Api';
import { GetEmployeeAdditionsDeductionsQueryVariables, GetEmployeeAdditionsDeductionsResponse } from '../types';

const getEmployeeAdditionsDeductionsQuery = gql`
  query PayrollGetEmployeeAdditionsDeductions($userId: String!, $effectiveFrom: String!, $effectiveTo: String!) {
  payrollGetEmployeeAdditionsDeductions(userId: $userId, effectiveFrom: $effectiveFrom, effectiveTo: $effectiveTo) {
    data {
      additions {
        id
        userId
        amount
        effectiveFrom
        effectiveTo
        type
        componentDetails {
          id
          name
          description
          defaultFormula
          category
          settings {
            behaviour {
              pfBehaviour {
                includeInPfWages
              }
              ptBehaviour {
                includeInPtWages
              }
              esiBehaviour {
                includeInEsiWages
              }
              lwfBehaviour {
                includeInLwfWages
              }
              taxBehaviour {
                taxExemptionSection
                taxDeductionBehaviour
                taxExemptInNewRegime
                taxExemptInOldRegime
              }
              arrearBehaviour {
                considerInArrear
              }
              prorationBehaviour {
                supportProration
              }
              displayBehaviour {
                showZero
                visibleToEmployee
                displayName
              }
            }
            earningDetails {
              payType
            }
            deductionDetails {
              deductionType
              deductFrom
            }
            bonusDetails {
              clawbackDuration
              isTaxable
            }
            perquisiteDetails {
              isTaxable
            }
            nonPayableBenefitsDetails {
              isPartOfCTC
              visibilityToEmployee
            }
          }
          isPredefined
          isActive
          source
        }
      }
      deductions {
        id
        userId
        amount
        effectiveFrom
        effectiveTo
        type
        componentDetails {
          id
          name
          description
          defaultFormula
          category
          settings {
            behaviour {
              pfBehaviour {
                includeInPfWages
              }
              ptBehaviour {
                includeInPtWages
              }
              esiBehaviour {
                includeInEsiWages
              }
              lwfBehaviour {
                includeInLwfWages
              }
              taxBehaviour {
                taxExemptionSection
                taxDeductionBehaviour
                taxExemptInNewRegime
                taxExemptInOldRegime
              }
              arrearBehaviour {
                considerInArrear
              }
              prorationBehaviour {
                supportProration
              }
              displayBehaviour {
                showZero
                visibleToEmployee
                displayName
              }
            }
            earningDetails {
              payType
            }
            deductionDetails {
              deductionType
              deductFrom
            }
            bonusDetails {
              clawbackDuration
              isTaxable
            }
            perquisiteDetails {
              isTaxable
            }
            nonPayableBenefitsDetails {
              isPartOfCTC
              visibilityToEmployee
            }
          }
          isPredefined
          isActive
          source
        }
      }
      lopData {
        peopleId
        lopDays
      }
    }
    code
    success
    message
  }
}
`;

const getEmployeeAdditionsDeductions = (queryVariables: GetEmployeeAdditionsDeductionsQueryVariables) => {
  return graphQLApi<GetEmployeeAdditionsDeductionsResponse, GetEmployeeAdditionsDeductionsQueryVariables>({
    query: getEmployeeAdditionsDeductionsQuery,
    queryVariables,
  });
};

export default getEmployeeAdditionsDeductions;
