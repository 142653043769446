import { DeductFromType } from "components/SalaryComponents/types";

export interface RunPayrollData {
  id: number;
  peopleId: number;
  employeeId: string;
  email: string;
  isPeopleActive: boolean;
  name: string;
  nameLink: string;
  adhocPaymentSum: number;
  applicableBonusSum: number;
  additions: DynamicAddition;
  salary: number;
  bonus: number;
  deduction: number | null;
  reimbursement: number | null;
  reimbursementLink: string;
  remarks: string;
  gross: number;
  payslipLink: string;
  isCancelled?: boolean;
  isBlocked?: boolean;
  isFinal?: boolean;
  adhocAllowances?: Additions[];
  allowances?: Additions[];
  arrearsSum: ArrearComponent | null;
  applicableBonus: ApplicableBonus[];
  hasFnf?: boolean;
  arrearsDistribution: { name: string; value: number }[] | null;
  complianceDistribution: { name: string; value: number }[] | null;
  rlopInfo: { month: string; rlopDays: number }[] | null;
  lastFinalizedPayrollMonth: string | null;
  deductions?: DeductionComponent;
  arrears: ArrearComponent[];
  peopleOrgId: number;
  changesStagingId: number | null;
  isChangeRequestPendingOnEdit: boolean;
  isNetPayOnHold?: boolean;
  newJoinerArrearPaymentMonth?: string | null;
  newJoinerInfo?: { arrearDays: number } | null;
}

export interface ArrearComponent {
  name: string;
  amount: number;
  type: string;
}

export interface HRA {
  actualHRA: number;
}

export interface DynamicAddition {
  [key: string]: {
    name: string;
    amount: number;
    taxable: number;
    type: number;
  };
}

export interface ApplicableBonus {
  name: string;
  amount: number;
  type: number;
}

export interface Additions {
  name: string;
  amount: number;
  type: number;
}

export interface AdditionsV2 {
  name: string;
  amount: number;
  type: string;
  id: string;
}

export interface DeductionComponent {
  non_lop_deductions: RunPayrollDeduction[];
  lop_deduction: RunPayrollDeduction;
  total_deduction: number;
}

export interface DeductionComponentV2 {
  non_lop_deductions: RunPayrollDeductionV2[];
  lop_deduction: RunPayrollDeductionV2;
  total_deduction: number;
}

export interface RunPayrollDeduction {
  [key: string]: any;
  name: string;
  amount: number;
  deduct_from: number;
  lop_days: string;
  type: number;
}

export interface RunPayrollDeductionV2 extends RunPayrollDeduction {
  id: string;
}

export enum DeductFrom {
  GROSS_PAY = 1,
  NET_PAY = 2,
}

export const DeductFromLabelToNumberMap: Record<DeductFromType, DeductFrom> = {
  'GROSS_PAY': DeductFrom.GROSS_PAY,
  'NET_PAY': DeductFrom.NET_PAY
}

export enum DeductionType {
  TYPE_MANUAL = 0,
  TYPE_AUTO_ATTENDANCE_LOP = 1,
  TYPE_MANUAL_ATTENDANCE_LOP = 2,
  TYPE_FLEXI_PARTNER_WALLET_DEDUCTION = 3,
  TYPE_JIBBLE_ATTENDANCE_LOP = 4,
}

export interface Filters {
  searchPhrase: string;
  departments: string[];
  locations: string[];
  statusFilter: string | null;
}

export interface PayrollMetaData {
  id: number;
  isCancelled: boolean;
  isBlocked: boolean;
  isFinal: boolean;
}

export interface OrderConfig {
  ORDER_BY_NAME?: string;
  ORDER_BY_CANCELLED?: string;
}

export const SkipTypes = {
  SKIPPED: 'SKIPPED',
  RESUME: 'RESUME',
};

export const orderValues = [null, 'desc', 'asc'];

export interface OrgLocation {
  code: number;
  name: string;
}

export const ADHOC_PAYMENTS_TYPE = 4;

export type DefinedRunPayrollData = Exclude<RunPayrollData['rlopInfo'], null>;
