import { AppError } from 'utils/AppError';

/**
 * Creates a retry policy function for React Query that only retries server errors (500+)
 * up to the specified maximum number of attempts.
 *
 * @param maxRetryAttempts - Maximum number of retry attempts allowed (default: 0, which means only retry once)
 * @returns A function with the signature expected by React Query's retry option
 */
export function createRetryPolicy(maxRetryAttempts: number = 1) {
  return function <E>(retryAttempt: number, error: E): boolean {
    // Don't retry after reaching the maximum attempts
    if (retryAttempt >= maxRetryAttempts) {
      return false;
    }

    // Only retry if it's a server error
    if (error instanceof AppError) {
      if ((error.getDetails().type as number) >= 500) {
        return true;
      }
    }

    return false;
  };
}
