import React from 'react';
import {
  SideNavBody,
  SideNav,
  SideNavLink,
  SideNavLevel,
  SideNavSection,
  SideNavLinkProps,
  SideNavFooter,
} from '@razorpay/blade/components';
import { matchPath, useLocation } from 'react-router-dom';
import { NavItem, NavItemKeys, createNavSections } from './navItems';
import { Location } from 'history';
import { SidebarApiSchemaContract } from 'schemas/SidebarApiSchema';

/**
 * Returns all hrefs in child tree for given item
 */
const getAllChildHrefs = (items?: NavItem[]) => {
  const hrefs: { path: string; href: string }[] = [];

  if (!items) {
    return [];
  }

  items.forEach((item) => {
    if (item.href) {
      const pathFromHref = new URL(item.href).pathname;
      hrefs.push({ path: pathFromHref, href: item.href });
    }
    if (item.items) {
      hrefs.push(...getAllChildHrefs(item.items));
    }
  });

  return hrefs;
};

const NavLink = (
  props: Omit<SideNavLinkProps, 'as'> & { activeOnLinks?: { href: string; path: string }[] },
) => {
  const location = useLocation();

  return (
    // "as" prop is marked required, but the code inside SideNavLink component doesn't require it
    // it uses anchor tag when as is missing, which is what we need
    // @ts-ignore
    <SideNavLink
      {...props}
      isActive={isItemActive(location, {
        href: props.href,
        activeOnLinks: props.activeOnLinks,
      })}
    />
  );
};

/**
 * Returns if the given href or one of the items from activeOnLinks are active
 */
const isItemActive = (
  location: Location,
  {
    href,
    activeOnLinks,
  }: { href: string | undefined; activeOnLinks?: { href: string; path: string }[] },
) => {
  if (!href) {
    return false;
  }

  const navItemUrl = new URL(href);
  // special handling for reimbursements as employees and contractors share the same route
  if (location.pathname === '/payReimbursement') {
    const employeeTypeFromLocation = new URLSearchParams(location.search).get('type');
    if (navItemUrl.pathname === '/payReimbursement') {
      const employeeTypeFromNavItem = navItemUrl.searchParams.get('type');

      if (employeeTypeFromLocation === employeeTypeFromNavItem) {
        return true;
      }
    }

    if (activeOnLinks) {
      const subItem = activeOnLinks.find((link) => matchPath(location.pathname, link.path));
      if (subItem) {
        const subItemEmployeeType = new URL(subItem.href).searchParams.get('type');
        if (employeeTypeFromLocation === subItemEmployeeType) {
          return true;
        }
      }
    }

    return false;
  }

  const isCurrentPathActive = Boolean(matchPath(location.pathname, navItemUrl.pathname));

  const isSubItemActive = Boolean(
    activeOnLinks?.find((link) => matchPath(location.pathname, link.path)),
  );

  return isCurrentPathActive || isSubItemActive;
};

export const SideBarV2 = ({
  sidebar,
  closeSidebar,
  isMobileSideBarOpen,
}: {
  sidebar: SidebarApiSchemaContract;
  closeSidebar: () => void;
  isMobileSideBarOpen: boolean;
}) => {
  const location = useLocation();
  const { sections, transformedItems } = createNavSections(sidebar);

  const getDefaultSectionExpanded = (items: NavItem[]) => {
    const activeItem = items.find((l1Item) => {
      return isItemActive(location, {
        href: l1Item.href,
        activeOnLinks: getAllChildHrefs(l1Item.items),
      });
    });

    return Boolean(activeItem);
  };

  if (Object.keys(sidebar).length === 0) {
    return null;
  }

  return (
    <SideNav isOpen={isMobileSideBarOpen} onDismiss={closeSidebar} position="absolute">
      <SideNavBody>
        {sections.map((l1Sections) => {
          return (
            <SideNavSection
              key={l1Sections.key}
              title={l1Sections.title}
              maxVisibleItems={l1Sections.maxItemsVisible}
              defaultIsExpanded={getDefaultSectionExpanded(
                l1Sections.items.slice(l1Sections.maxItemsVisible),
              )}>
              {l1Sections.items.map((l1Item) => {
                if (!l1Item.items) {
                  return <NavLink {...l1Item} />;
                }

                return (
                  <NavLink
                    {...l1Item}
                    activeOnLinks={getAllChildHrefs(l1Item.items)}
                    href={l1Item.items[0].href}>
                    <SideNavLevel key={l1Item.title}>
                      {l1Item.items?.map((l2Item) => {
                        if (!l2Item.items) {
                          return <NavLink {...l2Item} />;
                        }

                        return (
                          <NavLink
                            {...l2Item}
                            activeOnLinks={getAllChildHrefs(l2Item.items)}
                            href={undefined}>
                            <SideNavLevel key={l2Item.title}>
                              {l2Item.items?.map((l3Item) => {
                                return <NavLink {...l3Item} />;
                              })}
                            </SideNavLevel>
                          </NavLink>
                        );
                      })}
                    </SideNavLevel>
                  </NavLink>
                );
              })}
            </SideNavSection>
          );
        })}
      </SideNavBody>
      {(transformedItems[NavItemKeys.COMPANY_DETAILS]?.isEnabled ||
        transformedItems[NavItemKeys.SETTINGS]?.isEnabled) && (
        <SideNavFooter>
          {transformedItems[NavItemKeys.COMPANY_DETAILS].isEnabled ? (
            <NavLink
              title={transformedItems[NavItemKeys.COMPANY_DETAILS].title}
              icon={transformedItems[NavItemKeys.COMPANY_DETAILS].icon}
              href={transformedItems[NavItemKeys.COMPANY_DETAILS].href}></NavLink>
          ) : (
            <></>
          )}
          {transformedItems[NavItemKeys.SETTINGS].isEnabled ? (
            <NavLink
              title={transformedItems[NavItemKeys.SETTINGS].title}
              icon={transformedItems[NavItemKeys.SETTINGS].icon}
              href={transformedItems[NavItemKeys.SETTINGS].href}></NavLink>
          ) : (
            <></>
          )}
        </SideNavFooter>
      )}
    </SideNav>
  );
};
