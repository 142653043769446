import React, { HtmlHTMLAttributes, PropsWithChildren } from 'react';
import styles from './index.module.scss';
import Spinner from 'components/ui/Spinner';

const scale = {
  sm: 'text-2xl font-normal py-1',
  md: 'text-3xl font-bold py-2',
  lg: 'text-4xl font-bold leading-9 py-3',
};

export function SecondaryButton({
  size='sm',
  className,
  ...props
}: {
  size?: keyof typeof scale;
} & React.ButtonHTMLAttributes<HTMLButtonElement>) {
  size = size ? size : 'md';
  return (
    <button
      {...props}
      className={`${styles['button']}
        border border-solid text-interactiveTextPrimaryNormal px-6 py-2   
        hover:bg-primary-o-24 btn--secondary leading-[1] ${scale[size]} ${styles['button-secondary']} disabled:pointer-events-none ${
        className ? className : ''
      }`}></button>
  );
}

export function LinkButton({ className, children, ...props }: HtmlHTMLAttributes<HTMLDivElement>) {
  return (
    <div
      {...props}
      className={`${className ? className : ''} ${
        styles['link-button']
      } flex flex-col justify-center`}>
      {children}
    </div>
  );
}

export function LinkButtonV2({
  className,
  children,
  ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <button
      {...props}
      type="button"
      className={`${styles['link-button-v2']} ${className ? className : ''}`}>
      {children}
    </button>
  );
}

export function StandardButton({
  size,
  children,
  isPending,
  ...props
}: {
  size?: keyof typeof scale;
  isPending?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>) {
  size = size ? size : 'md';
  return (
    <button
      {...props}
      disabled={props.disabled || isPending}
      className={`${props.className ? props.className : ''} btn ${styles['standard-button']} `}>
      {children}
      {isPending && (
        <span className="ml-2 relative top-2">
          <Spinner size={14} />
        </span>
      )}
    </button>
  );
}

export function StandardSecondaryButton({
  size,
  children,
  isPending,
  ...props
}: {
  size?: keyof typeof scale;
  isPending?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>) {
  size = size ? size : 'md';
  return (
    <button
      {...props}
      disabled={props.disabled || isPending}
      className={`${props.className ? props.className : ''} btn--secondary ${
        styles['standard-button-secondary']
      }`}>
      {children}
      {isPending && (
        <span className="ml-2 relative top-2">
          <Spinner size={14} />
        </span>
      )}
    </button>
  );
}

export function PrimaryButton({
  size,
  children,
  isPending,
  isCompleted,
  ...props
}: {
  size?: keyof typeof scale;
  isPending?: boolean;
  isCompleted?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>) {
  size = size ? size : 'md';
  return (
    <button
      {...props}
      disabled={props.disabled || isPending || isCompleted}
      className={`btn ${props.className ? props.className : ''} px-6 py-2 ${
        isCompleted ? styles['primary-button-completed'] : styles['primary-button']
      } ${scale[size]}`}>
      {children}
      {isPending && (
        <span className="ml-2">
          <Spinner size={14} />
        </span>
      )}
      {isCompleted && <span className="ml-2 relative top-2">✓ COMPLETED</span>}
    </button>
  );
}

export function PrimaryButtonV2({
  children,
  isPending,
  ...props
}: {
  isPending?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <button
      {...props}
      disabled={props.disabled || isPending}
      className={`btn btn-outline-secondary flex flex-row ${
        props.className ? props.className : ''
      }`}>
      <>{children}</>
      {isPending && (
        <span className="ml-3 pt-5">
          <Spinner size={14} />
        </span>
      )}
    </button>
  );
}
