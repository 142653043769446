import { Box, CloseIcon, Heading, IconButton, ProgressBar } from '@razorpay/blade/components';
import React from 'react';
import { useWizardInfo } from '../providers/WizardInfoProvider';

type WizardHeaderProps = React.PropsWithChildren<{
  heading: string;
  subHeading: string;
  isProgressHidden?: boolean;
  onCloseClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}>;

export const WizardHeader = ({
  heading,
  subHeading,
  onCloseClick,
  children,
  isProgressHidden = false,
}: WizardHeaderProps) => {
  const { currentStep, totalSteps } = useWizardInfo();
  const progress = ((currentStep + 1) / totalSteps) * 100;
  return (
    <Box position="relative" backgroundColor="surface.background.gray.moderate" overflow="auto">
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={{ base: 'column', m: 'row' }}
        alignItems="center"
        position="relative"
        gap={{ base: 'spacing.7', m: 'spacing.0' }}
        padding={['spacing.5', 'spacing.8']}>
        <Box display="flex" justifyContent="space-between" width="100%" zIndex={1}>
          <Box display="flex" gap="spacing.2" alignItems="center">
            <Heading weight="regular" size="medium" color="surface.text.gray.muted">
              {heading}
            </Heading>
            <Heading size="medium" color="surface.text.gray.subtle">
              {subHeading}
            </Heading>
          </Box>

          <IconButton
            accessibilityLabel="Close creation wizard"
            icon={CloseIcon}
            onClick={onCloseClick}
          />
        </Box>
        <Box
          position={{ base: 'relative', m: 'absolute' }}
          top="0px"
          left="0px"
          width="100%"
          height="100%"
          display="flex">
          <Box margin="auto">{children}</Box>
        </Box>
      </Box>
      {!isProgressHidden && (
        <ProgressBar
          accessibilityLabel={`Step ${currentStep + 1} of ${totalSteps}`}
          variant="meter"
          value={progress}
        />
      )}
    </Box>
  );
};
