import { gql } from 'graphql-request';
import { graphQLApi } from 'utils/Api';
import {
  PayrollUpdateEmployeesAdditionsDeductionsLopRequest,
  UpdateEmployeeAdditionsDeductionsResponse,
} from '../types';

export const updateEmployeesAdditionsDeductionsLopMutation = gql`
  mutation PayrollUpdateEmployeeAdditionsDeductions(
    $userId: String!
    $additions: [ComponentAssignmentRequestInput!]!
    $deductions: [ComponentAssignmentRequestInput!]!
    $lopData: LopDaysUpdateInput!
  ) {
    payrollUpdateEmployeeAdditionsDeductions(
      userId: $userId
      additions: $additions
      deductions: $deductions
      lopData: $lopData
    ) {
      data {
        additions {
          id
          userId
          amount
          effectiveFrom
          effectiveTo
          type
          componentDetails {
            id
            name
            description
            defaultFormula
            category
            settings {
              behaviour {
                pfBehaviour {
                  includeInPfWages
                }
                ptBehaviour {
                  includeInPtWages
                }
                esiBehaviour {
                  includeInEsiWages
                }
                lwfBehaviour {
                  includeInLwfWages
                }
                taxBehaviour {
                  taxExemptionSection
                  taxDeductionBehaviour
                  taxExemptInNewRegime
                  taxExemptInOldRegime
                }
                arrearBehaviour {
                  considerInArrear
                }
                prorationBehaviour {
                  supportProration
                }
                displayBehaviour {
                  showZero
                  visibleToEmployee
                  displayName
                }
              }
              earningDetails {
                payType
              }
              deductionDetails {
                deductionType
                deductFrom
              }
              bonusDetails {
                clawbackDuration
                isTaxable
              }
              perquisiteDetails {
                isTaxable
              }
              nonPayableBenefitsDetails {
                isPartOfCTC
                visibilityToEmployee
              }
            }
            isPredefined
            isActive
            source
          }
        }
        deductions {
          id
          userId
          amount
          effectiveFrom
          effectiveTo
          type
          componentDetails {
            id
            name
            description
            defaultFormula
            category
            settings {
              behaviour {
                pfBehaviour {
                  includeInPfWages
                }
                ptBehaviour {
                  includeInPtWages
                }
                esiBehaviour {
                  includeInEsiWages
                }
                lwfBehaviour {
                  includeInLwfWages
                }
                taxBehaviour {
                  taxExemptionSection
                  taxDeductionBehaviour
                  taxExemptInNewRegime
                  taxExemptInOldRegime
                }
                arrearBehaviour {
                  considerInArrear
                }
                prorationBehaviour {
                  supportProration
                }
                displayBehaviour {
                  showZero
                  visibleToEmployee
                  displayName
                }
              }
              earningDetails {
                payType
              }
              deductionDetails {
                deductionType
                deductFrom
              }
              bonusDetails {
                clawbackDuration
                isTaxable
              }
              perquisiteDetails {
                isTaxable
              }
              nonPayableBenefitsDetails {
                isPartOfCTC
                visibilityToEmployee
              }
            }
            isPredefined
            isActive
            source
          }
        }
        lopData {
          peopleId
          lopDays
        }
      }
      code
      success
      message
    }
  }
`;

const updateEmployeesAdditionsDeductionsLop = (
  queryVariables: PayrollUpdateEmployeesAdditionsDeductionsLopRequest,
) => {
  return graphQLApi<
    UpdateEmployeeAdditionsDeductionsResponse,
    PayrollUpdateEmployeesAdditionsDeductionsLopRequest
  >({
    query: updateEmployeesAdditionsDeductionsLopMutation,
    queryVariables,
  });
};

export default updateEmployeesAdditionsDeductionsLop;
