import {
  ActionList,
  ActionListItem,
  Box,
  useToast,
  SearchInput,
  SearchInputProps,
  Spinner,
  ActionListItemText,
} from '@razorpay/blade/components';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import api from 'api';
import { AppError } from 'utils/AppError';
import { parseError } from 'utils/Api';
import { CACHE_TTL } from 'utils/cache';

function ChooseOrganization({
  currentOrgId,
  onSuccess,
}: {
  currentOrgId?: number;
  onSuccess?: () => void;
}) {
  const [searchQuery, setSearchQuery] = useState('');
  const location = useLocation();

  // Add function to clean URL params
  const getCleanPath = () => {
    const currentPath = `${location.pathname}${location.search}`;
    if (!location.search) return currentPath;

    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has('userId')) {
      searchParams.delete('userId');
      const newSearch = searchParams.toString();
      return `${location.pathname}${newSearch ? `?${newSearch}` : ''}`;
    }
    return currentPath;
  };

  const pathWithParams = encodeURIComponent(getCleanPath());
  const toast = useToast();

  const organizations = useQuery({
    queryKey: ['organizations'],
    queryFn: () => api.organization.fetchList(),
    onError: (e: typeof AppError) => {
      toast.show({
        content: parseError(e),
        color: 'negative',
      });
    },
    staleTime: CACHE_TTL.LARGE,
  });

  const switchOrgMutation = useMutation(
    (orgId: number) => api.organization.switchOrganization(orgId),
    {
      onSuccess: () => {
        onSuccess && onSuccess();
        toast.show({
          content: 'Switching organizations...',
          color: 'information',
        });
        window.location.href = decodeURIComponent(pathWithParams);
      },
      onError: (e: typeof AppError) => {
        toast.show({
          content: parseError(e),
          color: 'negative',
        });
      },
    },
  );

  const handleSearchQuery: SearchInputProps['onChange'] = (e) => {
    setSearchQuery(e.value || '');
  };

  return (
    <>
      <SearchInput
        showSearchIcon
        label=""
        isDisabled={switchOrgMutation.isLoading}
        accessibilityLabel="Search for your organization"
        placeholder="Search Organization"
        onChange={handleSearchQuery}
      />
      {organizations.isLoading ? (
        <Box
          marginTop="spacing.4"
          height="100%"
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center">
          <Spinner size="medium" accessibilityLabel="Fetching organizations you are a part of" />
        </Box>
      ) : (
        <></>
      )}
      {organizations.isSuccess ? (
        <ActionList>
          {organizations.data
            ?.filter((org) => {
              return org.name?.toLowerCase().includes(searchQuery.toLowerCase());
            })
            .sort((a, b) => {
              if (a.id === currentOrgId) return -1;
              if (b.id === currentOrgId) return 1;
              return (a.name || a.brand_name || '').localeCompare(b.name || b.brand_name || '');
            })
            .map((org) => (
              <ActionListItem
                key={org.id}
                title={org.name || org.brand_name || '-'}
                value={org.id.toString()}
                description={org.user_role_name}
                trailing={
                  org.id === currentOrgId ? (
                    <ActionListItemText>Logged in</ActionListItemText>
                  ) : undefined
                }
                isDisabled={switchOrgMutation.isLoading || org.id === currentOrgId}
                onClick={() => {
                  switchOrgMutation.mutate(org.id);
                }}
              />
            ))}
        </ActionList>
      ) : (
        <></>
      )}
    </>
  );
}

export default ChooseOrganization;
