import { GlobalSearchProps } from 'components/GlobalSearch/types';
import React from 'react';

const GlobalSearch = React.lazy(() => import('components/GlobalSearch/GlobalSearch'));

export const Search = ({
  specialPlatformPermissions = [],
  permissions,
  peopleId,
  userType,
  features = {},
}: GlobalSearchProps) => {
  return (
    <>
      {peopleId ? (
        <React.Suspense fallback={<></>}>
          <GlobalSearch
            userType={userType}
            permissions={permissions}
            specialPlatformPermissions={specialPlatformPermissions}
            peopleId={peopleId}
            features={features}
          />
        </React.Suspense>
      ) : null}
    </>
  );
};

export default Search;
