import React, { useState } from 'react';
import {
  TopNav,
  TopNavContent,
  useTheme,
  Box,
  Button,
  MenuIcon,
  Modal,
  ModalHeader,
  ModalBody,
  PowerIcon,
  TopNavActions,
} from '@razorpay/blade/components';
import Search from '../Search';
import { Notification } from '../Notification';
import UserProfile from '../UserProfile/UserProfile';
import OrgLogo from '../OrgLogo/OrgLogo';
import { GlobalSearchProps } from 'components/GlobalSearch/types';
import { isCAPartnerPage, isCheckEmailPage, isVerifyLogin2FaPage } from '../utils';
import { useLocation } from 'react-router-dom';
import ChooseOrganization from '../UserProfile/ChooseOrganization';
import { CompanyBrandWithSelector } from './CompanyBrandWithSelector';

const HeaderV2 = ({
  doesPageRequireLogin,
  name,
  companyBrandName,
  companyLogo,
  specialPlatformPermissions = [],
  permissions = [],
  peopleId,
  orgId,
  userPhoto,
  userType,
  features = {},
  hasMultipleOrgs = false,
  roleName = null,
  toggleMobileSideBar,
}: {
  doesPageRequireLogin: boolean;
  name?: string;
  companyBrandName?: string;
  orgId?: number;
  companyLogo?: string;
  userPhoto?: string | null;
  hasMultipleOrgs?: boolean;
  roleName?: string | null;
  toggleMobileSideBar?: () => void;
} & GlobalSearchProps) => {
  const { platform } = useTheme();
  const isMobile = platform === 'onMobile';
  const location = useLocation();
  const hasEditCompanyDetailsPermission = permissions.includes('EDIT_COMPANY_DETAILS');
  const hasThemeSwitcherPermission = specialPlatformPermissions.includes(
    'PLATFORM_PERMISSION_THEME_SWITCHER',
  );
  const [isSwitchOrgOpen, setIsSwitchOrgOpen] = useState(false);

  const handleOpenSwitchOrganization = () => {
    setIsSwitchOrgOpen(true);
  };

  const handleCloseSwitchOrganization = () => {
    setIsSwitchOrgOpen(false);
  };

  return (
    <TopNav backgroundColor="surface.background.gray.moderate">
      <OrgLogo logo={doesPageRequireLogin ? companyLogo : null} />
      {doesPageRequireLogin ? (
        peopleId ? (
          <>
            {isMobile ? (
              <>
                <Box></Box>
                <Box display="flex" gap="spacing.4">
                  <Notification />
                  <UserProfile
                    canSwitchTheme={hasThemeSwitcherPermission}
                    userPhoto={userPhoto}
                    peopleId={peopleId}
                    orgId={orgId}
                    name={name || ''}
                    companyBrandName={companyBrandName || ''}
                    hasMultipleOrgs={hasMultipleOrgs}
                    roleName={roleName}
                    onClickSwitchOrg={handleOpenSwitchOrganization}
                  />
                  <Button
                    variant="tertiary"
                    icon={MenuIcon}
                    accessibilityLabel="sidebar"
                    onClick={toggleMobileSideBar}
                  />
                </Box>
              </>
            ) : (
              <>
                <TopNavContent>
                  <></>
                </TopNavContent>
                <TopNavActions>
                  {companyBrandName &&
                    orgId &&
                    (hasEditCompanyDetailsPermission ? (
                      <a href="/companyDetails">
                        <CompanyBrandWithSelector
                          companyBrandName={companyBrandName}
                          orgId={orgId}
                          hasMultipleOrgs={hasMultipleOrgs}
                        />
                      </a>
                    ) : (
                      <CompanyBrandWithSelector
                        companyBrandName={companyBrandName}
                        orgId={orgId}
                        hasMultipleOrgs={hasMultipleOrgs}
                      />
                    ))}
                  <Search
                    userType={userType}
                    permissions={permissions}
                    specialPlatformPermissions={specialPlatformPermissions}
                    peopleId={peopleId}
                    features={features}
                  />
                  <Notification />
                  <UserProfile
                    canSwitchTheme={hasThemeSwitcherPermission}
                    roleName={roleName}
                    userPhoto={userPhoto}
                    peopleId={peopleId}
                    orgId={orgId}
                    name={name || ''}
                    companyBrandName={companyBrandName || ''}
                    hasMultipleOrgs={hasMultipleOrgs}
                    onClickSwitchOrg={handleOpenSwitchOrganization}
                  />
                </TopNavActions>
              </>
            )}
          </>
        ) : (
          <></>
        )
      ) : (
        <Box display="flex" justifyContent="flex-end">
          {(function renderWebsiteLink() {
            if (isCheckEmailPage(location)) {
              return (
                <a href={'https://razorpay.com/payroll'} target="_blank">
                  Go to Razorpay.com
                </a>
              );
            } else if (!isCAPartnerPage(location)) {
              if (isVerifyLogin2FaPage(location)) {
                return (
                  <Button
                    href="/logout"
                    variant="tertiary"
                    icon={PowerIcon}
                    accessibilityLabel="Logout"
                  />
                );
              } else
                return (
                  <a href={'https://razorpay.com/payroll'} target="_blank">
                    New to RazorpayX Payroll? Click here for our homepage!
                  </a>
                );
            }
          })()}
        </Box>
      )}
      <Modal
        accessibilityLabel="Switch Organization"
        isOpen={isSwitchOrgOpen}
        onDismiss={handleCloseSwitchOrganization}
        size="small">
        <ModalHeader title="Switch Organization" />
        <ModalBody>
          <ChooseOrganization currentOrgId={orgId} onSuccess={handleCloseSwitchOrganization} />
        </ModalBody>
      </Modal>
    </TopNav>
  );
};

export default HeaderV2;
