import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface EsicRegistrationSchemaContract {
  data: {
    organization_id: number;
    employee_id: string;
    people_id: number;
    user_attempts: number | null;
    employee_type: string;
    id_prefix: string | null;
    name: string;
    email: string;
    registration_status: 'DISABLED' | 'REGISTERED' | 'IN_PROGRESS' | 'FAILED' | 'NOT_INITIATED';
    remarks: string | null;
    employee_id_prefix: string;
    error_category: string | null;
    friendly_error: string | null;
    esic_registration_status_id: number | null;
    [k: string]: unknown;
  }[];
  total: number;
  [k: string]: unknown;
}

export default <JSONSchema4>{
  type: 'object',
  title: '',
  properties: {
    data: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          organization_id: { type: 'number' },
          employee_id: { type: 'string' },
          people_id: { type: 'number' },
          user_attempts: { type: ['number', 'null'] },
          employee_type: { type: 'string' },
          id_prefix: { type: ['string', 'null'] },
          name: { type: 'string' },
          email: { type: 'string' },
          registration_status: {
            type: 'string',
            enum: ['DISABLED', 'REGISTERED', 'IN_PROGRESS', 'FAILED', 'NOT_INITIATED'],
          },
          remarks: { type: ['string', 'null'] },
          employee_id_prefix: { type: 'string' },
          error_category: { type: ['string', 'null'] },
          friendly_error: { type: ['string', 'null'] },
          esic_registration_status_id: {type:['number', 'null']},
        },
        required: [
          'organization_id',
          'employee_id',
          'people_id',
          'user_attempts',
          'employee_type',
          'id_prefix',
          'name',
          'email',
          'registration_status',
          'remarks',
          'employee_id_prefix',
          'error_category',
          'friendly_error',
          'esic_registration_status_id',
        ],
      },
    },
    total: { type: 'number' },
  },
  required: ['data', 'total'],
};
