import { useSearchParams } from 'hooks/useSearchParams';
import { salaryComponentParams } from '../constants';
import { EarningPayType } from '../types';

export const useDetailsView = (param: string) => {
  const { addSearchParams, removeSearchParams, urlParams } = useSearchParams();

  const paramId = urlParams.get(param);
  const payType = urlParams.get(salaryComponentParams.earningPayType) as EarningPayType;

  const openDetailsView = (paramValue: number | string, payType?: EarningPayType) => {
    const newParams = [{ key: param, value: paramValue.toString() }];
    if (payType) {
      newParams.push({
        key: salaryComponentParams.earningPayType,
        value: payType,
      });
    }
    addSearchParams(newParams);
  };

  const closeDetailsView = () => {
    removeSearchParams([param, salaryComponentParams.earningPayType]);
  };

  return {
    paramId,
    payType,
    openDetailsView,
    closeDetailsView,
  };
};
