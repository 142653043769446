import {
  Badge,
  Box,
  Switch,
  Text,
  Tooltip,
  TooltipInteractiveWrapper,
} from '@razorpay/blade/components';
import React from 'react';

interface SwitchFieldProps extends React.ComponentProps<typeof Switch> {
  label: string;
  tooltip?: string;
  children?: React.ReactNode;
  isComingSoon?: boolean;
}

export const SwitchDescription = ({ content }: { content: string }) => {
  return (
    <Text as="span" color="surface.text.gray.muted" size="small">
      {content}
    </Text>
  );
};

export const SwitchField = ({
  label,
  tooltip,
  children,
  isComingSoon = false,
  ...switchprops
}: SwitchFieldProps) => {
  return (
    <Box position="relative">
      <Box
        as="label"
        display="flex"
        alignItems="center"
        gap="spacing.4"
        justifyContent="space-between">
        <Box display="flex" alignItems="center" gap="spacing.3">
          <Text weight="regular" variant="body" size="medium" color="surface.text.gray.subtle">
            {label}
          </Text>
          {isComingSoon && <Badge color="notice">Coming soon</Badge>}
        </Box>
        {tooltip ? (
          <Tooltip content={tooltip} zIndex={100}>
            <TooltipInteractiveWrapper>
              <Switch size="medium" {...switchprops} />
            </TooltipInteractiveWrapper>
          </Tooltip>
        ) : (
          <Switch size="medium" {...switchprops} />
        )}
      </Box>
      {children ? <Box paddingRight="spacing.9">{children}</Box> : null}
    </Box>
  );
};
