import { Box } from '@razorpay/blade/components';
import React from 'react';

export const StepActionsWrapper = ({ children }: React.PropsWithChildren<{}>) => (
  <Box
    display="flex"
    width="100%"
    position="sticky"
    bottom={'0px'}
    left={'0px'}
    backgroundColor="surface.background.gray.moderate">
    <Box
      maxWidth="700px"
      width={{ base: '100%', m: '700px' }}
      marginX="auto"
      paddingX={{ base: 'spacing.7', m: 'spacing.0' }}
      paddingTop="spacing.5"
      paddingBottom="spacing.7"
      display="flex"
      justifyContent="space-between"
      alignItems="center">
      {children}
    </Box>
  </Box>
);
