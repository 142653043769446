import api from 'api';
import { useSearchParams } from 'hooks/useSearchParams';
import { useMutation } from 'react-query';
import { AppError } from 'utils/AppError';
import { salaryComponentParams } from '../constants';

type UseModalActionsProps = {
  modalAction: string | null;
  onSuccess?: () => void;
  onError?: (error: AppError) => void;
};

export const useModalActions = ({modalAction, onSuccess, onError}: UseModalActionsProps) => {
  const { urlParams } = useSearchParams();
  const salaryComponentId = urlParams.get(salaryComponentParams.componentId) || '';

  const actionMutation = useMutation({
    mutationFn: api.salaryComponents.mutations.setSalaryComponentStatus,
    onSuccess: () => {
      onSuccess && onSuccess();
    },
    onError(error: AppError) {
      onError && onError(error);
    },
  });

  return {
    salaryComponentId,
    modalAction,
    actionMutation,
  };
};
