import { Box, Link, Text } from '@razorpay/blade/components';
import React from 'react';

export const FieldGroup = ({
  title,
  subtitle,
  children,
  infoLabel,
  onInfoClick,
}: React.PropsWithChildren<{
  title: string;
  subtitle?: string;
  infoLabel?: string;
  onInfoClick?: (event: React.SyntheticEvent) => void;
}>) => {
  return (
    <Box
      display="flex"
      gap={{ base: 'spacing.8', m: 'spacing.10' }}
      width="100%"
      flexDirection={{ base: 'column', m: 'row' }}>
      <Box
        display="flex"
        flexDirection="column"
        gap="spacing.3"
        minWidth="260px"
        maxWidth={{ base: 'auto', m: '260px' }}>
        <Text size="large" color="surface.text.gray.subtle" weight="semibold">
          {title}
        </Text>
        {subtitle ? (
          <Box>
            <Text as="span" size="small" color="surface.text.gray.muted">
              {subtitle}
            </Text>
            {infoLabel ? (
              <Link variant={'button'} marginLeft="spacing.2" size="small" onClick={onInfoClick}>
                {infoLabel}
              </Link>
            ) : null}
          </Box>
        ) : null}
      </Box>
      <Box display="flex" gap="spacing.7" flexDirection="column" flex={1}>
        {children}
      </Box>
    </Box>
  );
};
