import React, { useRef } from 'react';
import { useModal } from 'components/ui/Modals';
import styles from './index.module.scss';
import useClickOutside from 'hooks/useClickOutside';
import crossIcon from 'assets/icon-cross.svg'
import { getStaticMediaUrl } from 'utils/Urls';

type ModalProps = {
  disableClose?: boolean;
  shouldCloseOnOutsideClick?: boolean;
  onClose?: () => void;
  children: React.ReactNode;
};

type ModalTitleProps = {
  children: React.ReactNode;
  className?: string;
};

const Modal = ({ disableClose, children, shouldCloseOnOutsideClick = false, onClose }: ModalProps) => {
  const ref = useRef(null);
  const { closeModal } = useModal();

  useClickOutside({
    ref,
    onOutSideClick: () => (disableClose ? null : shouldCloseOnOutsideClick ? closeModal() : null),
  });

  const handleClose = () => {
    onClose && onClose();
    closeModal();
  };

  return (
    <div className={styles.Body} ref={ref}>
      {disableClose ? (
        <></>
      ) : (
        <button className={styles.Close} onClick={handleClose}>
          <img src={getStaticMediaUrl(crossIcon)} alt='close' />
        </button>
      )}

      <div className='modal-text'>{children}</div>
    </div>
  );
};

export const ModalTitle = ({ children, className = '' }: ModalTitleProps) => {
  return <h3 className={`text-4xl font-bold text-white-o-80 ${className}`}>{children}</h3>;
};

export default Modal;
