import { Box } from '@razorpay/blade/components';
import React from 'react';

export const WizardWrapper: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  return (
    <Box
      display="grid"
      gridTemplateRows="auto 1fr"
      overflowY="auto"
      maxHeight="100vh"
      height="100vh"
      backgroundColor="surface.background.gray.subtle">
      {children}
    </Box>
  );
};
