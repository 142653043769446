import { gql } from 'graphql-request';
import { graphQLApi } from 'utils/Api';
import { PayrollUpdateAdditionsRequest, UpdateAdditionsResponse } from '../types';

export const updateAdditionsMutation = gql`
  mutation PayrollUpdateAdditions($additions: [AdditionRequest!]) {
    payrollUpdateAdditions(additions: $additions) {
      code
      data {
        data {
          id
          componentName
          componentId
          userId
          type
          amount
          effectiveFrom
          effectiveTo
        }
        source
      }
      message
      success
    }
  }
`;

const updateAdditions = (queryVariables: PayrollUpdateAdditionsRequest) => {
  return graphQLApi<UpdateAdditionsResponse, PayrollUpdateAdditionsRequest>({
    query: updateAdditionsMutation,
    queryVariables,
  });
};

export default updateAdditions;
