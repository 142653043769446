import React, { useState } from 'react';
import {
  Box,
  Text,
  Menu,
  MenuItem,
  MenuOverlay,
  Avatar,
  HelpCircleIcon,
  LogOutIcon,
  UserIcon,
  Button,
  SettingsIcon,
  useTheme,
  BottomSheet,
  BottomSheetHeader,
  BottomSheetBody,
  ActionList,
  ActionListItem,
  BottomSheetFooter,
  ActionListItemIcon,
  Switch,
  ChevronRightIcon,
} from '@razorpay/blade/components';
import { ProfileCard } from './ProfileCard';
// import { ImageCache } from 'utils/ImageCache';
// import { prepareUrlV2 } from 'utils/Urls';

// const imageCache = new ImageCache({ cacheName: 'profile-pictures' });

const UserProfile = ({
  peopleId,
  orgId,
  userPhoto,
  name,
  roleName = null,
  companyBrandName,
  hasMultipleOrgs,
  canSwitchTheme,
  onClickSwitchOrg,
}: {
  peopleId: number;
  orgId?: number;
  name: string;
  userPhoto?: string | null;
  companyBrandName: string;
  hasMultipleOrgs: boolean;
  roleName?: string | null;
  canSwitchTheme: boolean;
  onClickSwitchOrg: () => void;
}) => {
  const { colorScheme, platform, setColorScheme } = useTheme();
  const isMobile = platform === 'onMobile';
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
  // const [validProfilePicUrl, setValidProfilePicUrl] = useState<string | null>(null);

  // useEffect(() => {
  //   if (userPhoto) {
  //     imageCache
  //       .getImage(prepareUrlV2('/getProfilePicture', { v: userPhoto }))
  //       .then(setValidProfilePicUrl);
  //   }
  // }, [userPhoto]);

  if (isMobile) {
    return (
      <Box>
        <Avatar
          testID="user-profile-avatar"
          size="medium"
          name={name || '👤'}
          variant="square"
          // src={validProfilePicUrl ? validProfilePicUrl : undefined}
          onClick={() => {
            setIsBottomSheetOpen((isOpen) => !isOpen);
          }}
        />
        <BottomSheet
          isOpen={isBottomSheetOpen}
          onDismiss={() => {
            setIsBottomSheetOpen(false);
          }}>
          <BottomSheetHeader
            title={name}
            subtitle={roleName ? roleName : undefined}></BottomSheetHeader>
          <BottomSheetBody>
            <ActionList>
              <ActionListItem
                title="My Profile"
                href="/employeeDetail"
                leading={<ActionListItemIcon icon={UserIcon} />}
                value="profile"
              />
              <ActionListItem
                title="Settings"
                href="/preferences"
                leading={<ActionListItemIcon icon={SettingsIcon} />}
                value="preferences"
              />
              <ActionListItem
                title="Help"
                value="help"
                href="/help"
                leading={<ActionListItemIcon icon={HelpCircleIcon} />}
              />
            </ActionList>
          </BottomSheetBody>
          <BottomSheetFooter>
            <Box display="flex" gap="spacing.4">
              <Button
                variant="tertiary"
                accessibilityLabel="Switch Organization"
                onClick={onClickSwitchOrg}>
                Switch Organization
              </Button>
              <Button variant="primary" color="negative" href="/logout">
                Logout
              </Button>
            </Box>
          </BottomSheetFooter>
        </BottomSheet>
      </Box>
    );
  }

  return (
    <Menu openInteraction="click">
      <Avatar
        size="medium"
        name={name || '👤'}
        testID="user-profile-avatar"
        variant="square"
        // src={validProfilePicUrl ? validProfilePicUrl : undefined}
      />
      <MenuOverlay>
        <Box>
          <ProfileCard href="/employeeDetail">
            <Box display="flex" gap="spacing.4" alignItems="center">
              <Avatar
                variant="square"
                size="medium"
                name={name}
                color="information"
                // src={validProfilePicUrl ? validProfilePicUrl : undefined}
              />
              <Box display="flex" flexDirection="column" gap="spacing.1">
                <Text size="medium" weight="semibold">
                  {name}
                </Text>
                <Text size="small" color="surface.text.gray.muted">
                  {roleName}
                </Text>
              </Box>
            </Box>
            <ChevronRightIcon color="feedback.icon.information.intense" />
          </ProfileCard>
          {hasMultipleOrgs && (
            <Box
              padding="spacing.4"
              borderColor="surface.border.gray.muted"
              borderWidth="thin"
              borderRadius="medium">
              <Text>{companyBrandName}</Text>
              <Button
                marginTop="spacing.2"
                size="xsmall"
                isFullWidth
                variant="tertiary"
                accessibilityLabel="Switch Organization"
                onClick={onClickSwitchOrg}>
                Switch Organization
              </Button>
            </Box>
          )}
        </Box>
        <Box paddingBottom="spacing.2"></Box>
        {canSwitchTheme && (
          <Box as="label" display="flex" alignItems="center" gap="spacing.2" marginLeft="spacing.3">
            <Text weight="regular" variant="body" size="medium">
              Toggle light theme
            </Text>
            <Switch
              accessibilityLabel="Toggle light theme"
              size="small"
              onChange={() => setColorScheme(colorScheme === 'light' ? 'dark' : 'light')}
              isChecked={colorScheme === 'light'}
            />
          </Box>
        )}

        <MenuItem
          leading={<SettingsIcon color="interactive.icon.gray.muted" />}
          title="Settings"
          href="/preferences"
        />
        <MenuItem
          leading={<HelpCircleIcon color="interactive.icon.gray.muted" />}
          title="Help"
          href="/help"
        />
        <MenuItem
          leading={<LogOutIcon color="feedback.icon.negative.intense" />}
          color="negative"
          title="Logout"
          href="/logout"
        />
      </MenuOverlay>
    </Menu>
  );
};

export default UserProfile;
