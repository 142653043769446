import { gql } from 'graphql-request';
import { graphQLApi } from 'utils/Api';
import { PayrollGetSalaryComponentQueryVariables, GetSalaryComponentResponse } from '../types';

export const getSalaryComponentQuery = gql`
  query PayrollGetSalaryComponent(
    $id: String!
    $category: SalaryComponentCategory!
    $payType: SalaryComponentEarningPayType
  ) {
    payrollGetSalaryComponent(id: $id, category: $category, payType: $payType) {
      data {
        source
        salaryComponent {
          id
          name
          description
          defaultFormula
          category
          isPredefined
          isActive
          settings {
            behaviour {
              pfBehaviour {
                includeInPfWages
              }
              ptBehaviour {
                includeInPtWages
              }
              esiBehaviour {
                includeInEsiWages
              }
              lwfBehaviour {
                includeInLwfWages
              }
              taxBehaviour {
                taxExemptionSection
                taxDeductionBehaviour
                taxExemptInNewRegime
                taxExemptInOldRegime
              }
              arrearBehaviour {
                considerInArrear
              }
              prorationBehaviour {
                supportProration
              }
              displayBehaviour {
                showZero
                visibleToEmployee
                displayName
              }
            }
            earningDetails {
              payType
            }
            deductionDetails {
              deductionType
              deductFrom
            }
            bonusDetails {
              clawbackDuration
              isTaxable
            }
            perquisiteDetails {
              isTaxable
            }
            nonPayableBenefitsDetails {
              isPartOfCTC
              visibilityToEmployee
            }
          }
          source
        }
      }
      code
      success
      message
    }
  }
`;

const getSalaryComponent = <T>(queryVariables: PayrollGetSalaryComponentQueryVariables) => {
  if (queryVariables.id) {
    return graphQLApi<GetSalaryComponentResponse<T>, PayrollGetSalaryComponentQueryVariables>({
      query: getSalaryComponentQuery,
      queryVariables,
    });
  } else {
    return Promise.reject('ComponentID is required');
  }
};

export default getSalaryComponent;
