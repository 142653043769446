import { createSlice } from '@reduxjs/toolkit';
import { ReduxState } from 'reducers';

export interface RunPayrollState {
  isSkippedCountConsentGranted: boolean;
}

const initialState: RunPayrollState = {
  isSkippedCountConsentGranted: false,
};

const runPayrollSlice = createSlice({
  name: 'runPayroll',
  initialState,
  reducers: {
    setIsSkippedCountConsentGranted: (state, action: { payload: boolean }) => {
      state.isSkippedCountConsentGranted = action.payload;
    },
    resetIsSkippedCountConsentGranted: (state) => {
      state.isSkippedCountConsentGranted = false;
    },
  },
});

export const { setIsSkippedCountConsentGranted, resetIsSkippedCountConsentGranted } =
  runPayrollSlice.actions;
export const runPayroll = runPayrollSlice.reducer;

export const selectIsSkippedCountConsentGranted = (state: ReduxState) =>
  state.runPayroll.isSkippedCountConsentGranted;
