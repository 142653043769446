import { NUMERIC_REGEX } from './regexes';

export function toIndianCurrencyString(
  amount: number,
  withoutSign: boolean = false,
  fractionDigits: number = 0,
) {
  return `${!withoutSign ? '₹' : ''}${amount.toLocaleString('en-IN', {
    minimumFractionDigits: fractionDigits,
  })}`;
}

export function getNumberFromIndianCurrencyString(amount: string) {
  return +amount.replace(/ |₹|,/g, '');
}

let id = 0;

export const uniqueId = () => {
  const dateString = Date.now().toString(36);
  const randomness = Math.random().toString(36).substring(2);
  return dateString + randomness + id++;
};

export const isNumeric = (value: string | undefined) => {
  if (value) {
    const regex = new RegExp(NUMERIC_REGEX);
    return regex.test(value);
  }
  return true;
};

export const rupeeAmountToPaise = (amount: number = 0) => {
  return amount * 100.0;
};

export const paiseAmountToRupee = (amount: number = 0) => {
  return amount / 100.0;
};
