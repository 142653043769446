import { routePaths } from "components/Routes/data";
import { Location } from "history";

export const isCAPartnerPage = (location: Location) => {
  return location.pathname.includes('special-offers-chartered-accountants');
};

export const isCheckEmailPage = (location: Location) => {
  return location.pathname.includes('checkEmailVerification');
};

export const isVerifyLogin2FaPage = (location: Location) => {
  return location.pathname.includes(routePaths.login.otp);
}
