import { ReduxState } from 'reducers';
import { FeatureFlags } from 'types';
import Allowance, { amountTypes, taxableTypes } from 'utils/Allowance';

export const permissions = (state: ReduxState) => {
  return state?.loggedInUser?.data?.currentOrganization?.employeeDetails?.permissions || [];
};

export const specialPlatformPermissions = (state: ReduxState): string[] => {
  return state?.loggedInUser?.data?.specialPlatformPermissions ?? [];
};

export const role = (state: ReduxState) => {
  return state?.loggedInUser?.data?.currentOrganization?.employeeDetails?.userRoleId || null;
};

export const features = (state: ReduxState) => {
  return (
    (state?.loggedInUser?.data?.currentOrganization?.features as
      | Record<FeatureFlags, boolean>
      | undefined) || null
  );
};

export const departments = (state: ReduxState) => {
  return state?.loggedInUser?.data?.currentOrganization?.departments || [];
};

export const locations = (state: ReduxState) => {
  return state?.loggedInUser?.data?.currentOrganization?.locations || [];
};

export const actionsWithActiveWorkflows = (state: ReduxState) => {
  return state?.loggedInUser?.data?.currentOrganization?.actionsWithActiveWorkflows || [];
};

export const slackClientId = (state: ReduxState) => {
  return state?.loggedInUser?.data?.appConfig?.slackClientId;
};

export const organizationBrandName = (state: ReduxState) => {
  return state?.loggedInUser?.data?.currentOrganization?.brandName;
};

export const slackScopes = (state: ReduxState) => {
  return state?.loggedInUser?.data?.appConfig?.slackScopes;
};

export const isOnFreePlan = (state: ReduxState) => {
  return state?.loggedInUser.data?.currentOrganization?.planName === 'free';
};

export const isOnProPlan = (state: ReduxState) => {
  return state?.loggedInUser.data?.currentOrganization?.planName === 'pro';
};

export const organizationID = (state: ReduxState) => {
  return state?.loggedInUser.data?.currentOrganization?.id;
};

export const referenceLinks = (state: ReduxState) => {
  return state?.loggedInUser.data?.appConfig?.referenceLinks || {};
};

export const appConfig = (state: ReduxState) => {
  return state?.loggedInUser.data?.appConfig || {};
};

export const email = (state: ReduxState) => {
  if (state?.loggedInUser?.data?.email) {
    return state?.loggedInUser?.data?.email as string;
  }
  return null;
};

export const name = (state: ReduxState) => {
  return state?.loggedInUser?.data?.name || null;
};

export const organizationCustomAllowances = (state: ReduxState) => {
  return (
    state?.loggedInUser.data?.currentOrganization?.salaryStructureConfig?.customAllowances || []
  ).map(
    (allowance) =>
      new Allowance({
        name: allowance.name,
        value: allowance.percentage || allowance.amount || 0,
        amountType: allowance.percentage
          ? amountTypes.ALLOWANCE_AMOUNT_TYPE_PERCENTAGE
          : amountTypes.ALLOWANCE_AMOUNT_TYPE_FIXED,
        taxableType: allowance.taxable,
      }),
  );
};

export const organizationDefaultSalaryStructure = (state: ReduxState) => {
  return state?.loggedInUser.data?.currentOrganization?.defaultSalaryStructureConfig || {};
};

export const currentOrganization = (state: ReduxState) => {
  return state?.loggedInUser.data?.currentOrganization;
};

export const isOrgKycRestricted = (state: ReduxState) => {
  return state?.loggedInUser.data?.currentOrganization?.isKycRestricted;
};

export const kycStatus = (state: ReduxState) => {
  return state?.loggedInUser.data?.currentOrganization?.kycStatus;
};

export const isOrgRXIntegrated = (state: ReduxState) => {
  return state?.loggedInUser.data?.currentOrganization?.isRXIntegrated;
};

export const peopleOrgId = (state: ReduxState) => {
  return state?.loggedInUser.data?.currentOrganization?.employeeDetails?.id;
};

export const peopleId = (state: ReduxState) => {
  return state?.loggedInUser.data?.currentOrganization?.employeeDetails?.peopleId;
};

export const hasData = (state: ReduxState) => {
  return !!state?.loggedInUser?.data;
};

export const isTwoFactorAuthenticationEnabled = (state: ReduxState) => {
  return !!state?.loggedInUser?.data?.currentOrganization?.isTwoFactorAuthenticationEnabled;
};

const numOfEmployees = (state: ReduxState) => {
  return state.loggedInUser.data?.currentOrganization?.noOfEmployees;
};

export const disablePfPaymentsFlag = (state: ReduxState): boolean => {
  return state.loggedInUser.data?.currentOrganization?.disablePfPaymentsFlag ?? false;
};

export const disablePfRegistrationFlag = (state: ReduxState): boolean => {
  return state.loggedInUser.data?.currentOrganization?.disablePfRegistrationFlag ?? false;
};

export const employeeDetails = (state: ReduxState) => {
  return state?.loggedInUser.data?.currentOrganization?.employeeDetails;
};

export const isAuthenticatorAppEnabled = (state: ReduxState) => {
  return Boolean(
    state?.loggedInUser.data?.currentOrganization?.employeeDetails?.isAuthenticatorAppEnabled,
  );
};

export const leaveAttendanceConfig = (state: ReduxState) => {
  return state?.loggedInUser.data?.currentOrganization?.leaveAttendanceConfig;
};

export const managerPeopleId = (state: ReduxState) => {
  return state?.loggedInUser.data?.currentOrganization?.employeeDetails?.managerPeopleId;
};

const loggedInUserSelectors = {
  permissions,
  role,
  features,
  departments,
  locations,
  slackClientId,
  organizationBrandName,
  slackScopes,
  isOnFreePlan,
  isOnProPlan,
  organizationID,
  referenceLinks,
  appConfig,
  organizationCustomAllowances,
  organizationDefaultSalaryStructure,
  currentOrganization,
  peopleOrgId,
  peopleId,
  specialPlatformPermissions,
  email,
  hasData,
  isOrgKycRestricted,
  isOrgRXIntegrated,
  name,
  actionsWithActiveWorkflows,
  isTwoFactorAuthenticationEnabled,
  numOfEmployees,
  disablePfPaymentsFlag,
  disablePfRegistrationFlag,
  employeeDetails,
  isAuthenticatorAppEnabled,
  kycStatus,
  leaveAttendanceConfig,
};

export default loggedInUserSelectors;
