import { JSONSchema4 } from 'json-schema';

export interface OrganizationContract {
  id: number;
  name: string | null;
  brand_name: string | null;
  user_role_id: number;
  user_role_name: string;
  [k: string]: unknown;
}

export const OrganizationSchema: JSONSchema4 = {
  type: 'array',
  items: {
    type: 'object',
    properties: {
      id: { type: 'integer' },
      name: { type: ['string', 'null'] },
      brand_name: { type: ['string', 'null'] },
      user_role_id: { type: 'integer' },
      user_role_name: { type: 'string' },
    },
    required: ['id', 'user_role_id', 'user_role_name'],
    additionalProperties: true,
  },
};
