import { gql } from 'graphql-request';
import { graphQLApi } from 'utils/Api';
import { BulkSkipPayrollResponse } from '../types';

const bulkSkipPayrollMutation = gql`
  mutation PayrollBulkSkip($payroll_month: String!, $ids: [PositiveInt]!) {
    payrollBulkSkip(payroll_month: $payroll_month, ids: $ids) {
      code
      data {
        success
      }
      message
      success
    }
  }
`;

const bulkSkipPayroll = (queryVariables: { payroll_month: String; ids: number[] }) => {
  return graphQLApi<BulkSkipPayrollResponse, { payroll_month: String; ids: number[] }>({
    query: bulkSkipPayrollMutation,
    queryVariables,
  });
};

export default bulkSkipPayroll;
